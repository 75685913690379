import { useState } from 'react';
import { Box, Button, Container, Modal, Typography } from '@mui/material';
import demoPreview from '../imgs/demo-preview.webp';
import { styled } from '@mui/material/styles';
import { PopupModal } from 'react-calendly';
import ContactForm from '../components/contact_form';

const IframeContainer = styled('div')(({ theme }) => ({
	position: 'relative',
	width: '100%',
	maxWidth: '560px',
	maxHeight: '315px',
	aspectRatio: 16 / 9,
	backgroundImage: `url(${demoPreview})`,
	backgroundSize: 'cover',
	backgroundPosition: 'center',
	'& iframe': {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		border: 'none',
		opacity: 0,
		transition: theme.transitions.create('opacity', {
			easing: theme.transitions.easing.easeInOut,
			duration: theme.transitions.duration.standard,
		}),
	},
	'& iframe.loaded': {
		opacity: 1,
	},
	order: { xs: 0, md: 12 },
}));

function GreenInitialButton({ children, ...otherProps }) {
	return (
		<Button variant='green' sx={{ width: 'fit-content', textTransform: 'initial' }} {...otherProps}>
			{children}
		</Button>
	);
}

function MakeAppointmentButton() {
	const [isOpen, setIsOpen] = useState(false);
	const toggleCalendlyPopup = () => setIsOpen(!isOpen);

	return (
		<>
			<PopupModal
				url='https://calendly.com/paul-roesler/30min?hide_gdpr_banner=1'
				rootElement={document.getElementById('root')}
				onModalClose={toggleCalendlyPopup}
				open={isOpen}
			/>
			<GreenInitialButton onClick={toggleCalendlyPopup}>Schedule a Meeting</GreenInitialButton>
		</>
	);
}

function ContactUsButton() {
	const [isOpen, setIsOpen] = useState(false);

	const toggleContactModal = () => setIsOpen(!isOpen);

	return (
		<>
			<GreenInitialButton onClick={toggleContactModal}>Contact US</GreenInitialButton>

			<Modal open={isOpen} onClose={toggleContactModal}>
				<ContactForm />
			</Modal>
		</>
	);
}

function RequestDemo() {
	const [iframeLoaded, setIframeLoaded] = useState(false);
	const handleIframeLoad = () => {
		setIframeLoaded(true);
	};

	return (
		<Container>
			<Box marginBottom={20}>
				<Box marginY={6}>
					<Typography variant='pageHeader'>Request a Demo</Typography>
				</Box>
				<Box
					display='flex'
					alignItems='center'
					marginBottom={4}
					gap={4}
					flexDirection={{ xs: 'column', md: 'row' }}
				>
					<Box display='inline-flex' flex={1} order={{ xs: 12, md: 0 }} flexDirection='column'>
						<Box marginY={4} display='flex' flexDirection='column'>
							<Typography variant='body' component='p'>
								See how our platform can help your business grow.
							</Typography>
							<Typography variant='body' component='p'>
								Schedule a personalized demo with one of our experts.
							</Typography>
						</Box>
						<Box display='inline-flex' gap={4}>
							<MakeAppointmentButton />
							<ContactUsButton />
						</Box>
					</Box>
					<IframeContainer>
						<iframe
							width='560'
							height='315'
							src='https://www.youtube.com/embed/O-_uypUGqfE?rel=0&amp;autoplay=1&mute=1'
							title='Oxari - Athena Walkthrough'
							allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
							referrerPolicy='strict-origin-when-cross-origin'
							frameBorder={0}
							onLoad={handleIframeLoad}
							className={iframeLoaded ? 'loaded' : ''}
						/>
					</IframeContainer>
				</Box>
			</Box>
		</Container>
	);
}
export default RequestDemo;
