import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Grid } from '@mui/material';
import {
	BarChart,
	Bar,
	PieChart,
	Pie,
	Sector,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	Label,
	Cell,
} from 'recharts';
import { useTheme } from '@mui/material/styles';

import { BACKEND_URL } from '../components/constants';

const smeCompanyCounts = [
	{
		name: 'SMEs',
		company_count: 15080,
		company_count_perc: '28.8%',
	},
	{
		name: 'Non SMEs',
		company_count: 37232,
		company_count_perc: '71.2%',
	},
];

const emergingMktCompanyCounts = [
	{
		name: 'Emerging Markets',
		company_count: 18089,
		company_count_perc: '34.6%',
	},
	{
		name: 'Non Emerging Markets',
		company_count: 34223,
		company_count_perc: '65.4%',
	},
];

const renderActiveShape = (props) => {
	const RADIAN = Math.PI / 180;
	const {
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		startAngle,
		endAngle,
		fill,
		payload,
		percent,
		value,
	} = props;
	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius + 5) * cos;
	const sy = cy + (outerRadius + 5) * sin;
	const mx = cx + (outerRadius + 15) * cos;
	const my = cy + (outerRadius + 15) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 11;
	const ey = my;
	const textAnchor = cos >= 0 ? 'start' : 'end';

	return (
		<g>
			<text x={cx} y={cy} dy={8} textAnchor='middle' fill={fill} fontSize='0.9em'>
				{payload.name.split(' ').map((word, index) => (
					<tspan key={index} x={cx} dy={index === 0 ? 3 : 15}>
						{word}
					</tspan>
				))}
			</text>
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				startAngle={startAngle}
				endAngle={endAngle}
				fill={fill}
			/>
			<Sector
				cx={cx}
				cy={cy}
				startAngle={startAngle}
				endAngle={endAngle}
				innerRadius={outerRadius + 6}
				outerRadius={outerRadius + 10}
				fill={fill}
			/>
			<path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill='none' />
			<circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
			<text
				x={ex + (cos >= 0 ? 1 : -1) * 12}
				y={ey}
				textAnchor={textAnchor}
				fill='#333'
				fontSize='0.8em'
			>{`${value}`}</text>
			<text
				x={ex + (cos >= 0 ? 1 : -1) * 12}
				y={ey}
				dy={18}
				textAnchor={textAnchor}
				fill='#999'
				fontSize='0.7em'
			>
				{`(${(percent * 100).toFixed(2)}%)`}
			</text>
		</g>
	);
};

const RingChartContainer = ({ chartPosition = 'left', data = null, children }) => {
	const isLeftChart = chartPosition.toLowerCase() === 'left';
	const [activeIdx, setActiveIdx] = useState(0);
	const theme = useTheme();

	const onPieEnter = (_, index) => setActiveIdx(index);

	if (!data)
		return (
			<Box width='100%' height='100%'>
				<Typography variant='body'>No data available.</Typography>
			</Box>
		);
	return (
		<Grid container alignItems={'center'} direction={isLeftChart ? 'row' : 'row-reverse'}>
			<Grid
				item
				xs={12}
				md={6}
				justifyContent={{ xs: 'center', md: isLeftChart ? 'end' : 'start' }} // if the responsiveContainer width is not 100%
				display={'inline-flex'}
			>
				<ResponsiveContainer width={'100%'} height={240}>
					<PieChart>
						<Pie
							activeIndex={activeIdx}
							activeShape={renderActiveShape}
							data={data}
							cx='50%'
							cy='50%'
							innerRadius={75}
							outerRadius={90}
							fill='#8884d8'
							dataKey='company_count'
							onMouseEnter={onPieEnter}
							startAngle={isLeftChart ? 90 : 270}
							endAngle={isLeftChart ? 450 : 630}
						>
							{data.map((_, index) => (
								<Cell
									key={`cell-${index}`}
									fill={
										index === activeIdx
											? theme.palette.primary.bright_green
											: theme.palette.secondary.main
									}
								/>
							))}
						</Pie>
					</PieChart>
				</ResponsiveContainer>
			</Grid>
			<Grid item xs md={6} sx={{ textAlign: isLeftChart ? 'left' : 'right' }} padding={2}>
				{children}
			</Grid>
		</Grid>
	);
};

const transformedData = (response) => {
	const stats = response.data.payload.statistics;
	const confidenceIntervals = stats.confidence_rating;
	const totalCompanies = stats.meta.num_companies_tested;
	const totalDataPoints = stats.meta.num_rows_tested;
	const scopes = ['scope_1', 'scope_2', 'scope_3'];

	const intervals = Object.keys(confidenceIntervals.scope_1)
		.slice(0, -3)
		.map((key) => {
			let intervalLabel = `${confidenceIntervals.scope_1[key].left}-${confidenceIntervals.scope_1[key].right}`;
			return {
				interval: intervalLabel,
				scope_1: ((confidenceIntervals.scope_1[key].cnt / totalCompanies) * 100).toFixed(2),
				scope_2: ((confidenceIntervals.scope_2[key].cnt / totalCompanies) * 100).toFixed(2),
				scope_3: ((confidenceIntervals.scope_3[key].cnt / totalCompanies) * 100).toFixed(2),
			};
		});

	const aggregateLabel = `>${
		confidenceIntervals.scope_1[Object.keys(confidenceIntervals.scope_1).length - 3].left
	}`;
	const aggregateCounts = scopes.reduce((acc, scope) => {
		acc[scope] = Object.keys(confidenceIntervals[scope])
			.slice(-3)
			.reduce((sum, key) => sum + confidenceIntervals[scope][key].cnt, 0);
		return acc;
	}, {});

	intervals.push({
		interval: aggregateLabel,
		scope_1: ((aggregateCounts.scope_1 / totalCompanies) * 100).toFixed(2),
		scope_2: ((aggregateCounts.scope_2 / totalCompanies) * 100).toFixed(2),
		scope_3: ((aggregateCounts.scope_3 / totalCompanies) * 100).toFixed(2),
	});

	return [intervals, totalCompanies, totalDataPoints];
};

export default function ConfidenceRating() {
	const [confidenceRating, setConfidenceRating] = React.useState([]);
	const [totalCompanies, setTotalCompanies] = React.useState(0);
	const [totalDataPoints, setTotalDataPoints] = React.useState(0);

	useEffect(() => {
		async function fetchData() {
			try {
				const headers = {
					accept: 'application/json',
				};

				const response = await axios.get(BACKEND_URL + '/model/internal/statistics', {
					headers: headers,
				});
				// console.log(response.data.payload.statistics);

				const data = transformedData(response);

				setConfidenceRating(data[0]);
				setTotalCompanies(data[1]);
				setTotalDataPoints(data[2]);

				// setDataFetched(true);
			} catch (error) {
				console.error(error);
			}
		}

		fetchData();
	}, []);

	return (
		<>
			<Grid item width='100%' height='100%' mt={10} mb={10}>
				<Typography variant='h4' align='justify' fontWeight={'bold'} gutterBottom>
					Prediction Confidence Rating by Scope
				</Typography>
				<ResponsiveContainer width='100%' height={400}>
					<BarChart data={confidenceRating} margin={{ right: -40, left: 40, bottom: 40 }}>
						<CartesianGrid strokeDasharray='3 3' />
						<XAxis dataKey='interval'>
							<Label value='Predictions Confidence (%)' offset={10} position='bottom' />
						</XAxis>
						<YAxis>
							<Label
								value='Companies (%)'
								angle={-90}
								offset={10}
								position='left'
								style={{ textAnchor: 'middle' }}
							/>
						</YAxis>
						<Tooltip />
						<Legend
							layout='vertical'
							verticalAlign='top'
							align='right'
							wrapperStyle={{
								top: 40,
								right: 150,
								backgroundColor: 'rgba(255, 255, 255, 0.8)',
							}}
						/>
						<Bar dataKey='scope_1' fill='rgba(19, 234, 75, 1)' name='Scope 1' />
						<Bar dataKey='scope_2' fill='#909090' name='Scope 2' />
						<Bar dataKey='scope_3' fill='#39393A' name='Scope 3' />
					</BarChart>
					<Typography fontWeight={'bold'} textAlign='start'>
						*Tested on {totalCompanies} companies and {totalDataPoints} scope observations.
					</Typography>
				</ResponsiveContainer>
			</Grid>
			<Grid item xs={12}>
				<RingChartContainer data={smeCompanyCounts} chartPosition='left'>
					<Typography variant='h5'>SMEs Data</Typography>
					<Typography variant='body'>
						Actionable insights to optimize operations, streamline processes, and drive growth for
						small to medium-sized enterprises.
					</Typography>
				</RingChartContainer>
			</Grid>
			<Grid item xs={12}>
				<RingChartContainer data={emergingMktCompanyCounts} chartPosition='right'>
					<Typography variant='h5'>Emerging Markets Data</Typography>
					<Typography variant='body'>
						Navigating growth opportunities and risks with data-driven insights to succeed in
						dynamic, fast-paced economies.
					</Typography>
				</RingChartContainer>
			</Grid>
		</>
	);
}
