import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
	Container,
	Grid,
	Typography,
	Box,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	// Card,
	// Paper,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	// ListItemAvatar,
	Chip,
	Stack,
	// Avatar,
} from '@mui/material';
import { datasets } from '../misc/text/datasets';
import { useParams, useLocation } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/AddCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ContactButton } from '../components/contact_button';
import { TrySampleButton } from './dataset_overiew';

// TODO: Add helmet to each page
// TODO: Add helmet to dataset page
// TODO: Add sitemaps

function DataDictTable({ dataDict }) {
	function mapColors(dataType) {
		if (dataType === 'string') {
			return 'info';
		}
		if (dataType === 'float') {
			return 'primary';
		}
		if (dataType === 'boolean') {
			return 'warning';
		}
		return 'error';
	}

	return (
		<>
			<List>
				{dataDict.map((elem, idx) => (
					<ListItem key={idx}>
						{/* <Grid container> */}
						{/* <Grid item xs={4}> */}
						{/* <ListItemAvatar> */}

						{/* <Avatar>
								</Avatar> */}
						{/* </ListItemAvatar> */}
						<ListItemText
							primary={
								<Stack direction={'row'} spacing={2}>
									<Typography variant={'body1'}>{elem.varName}</Typography>
									<Chip label={elem.dataType} color={mapColors(elem.dataType)} />
								</Stack>
							}
							secondary={
								<>
									<Typography variant='body2'>
										{elem.description} Example: {elem.example}
									</Typography>
								</>
							}
						></ListItemText>
						{/* </Grid> */}
						{/* <Grid item xs={4}>{elem.example}</Grid> */}
						{/* <Grid item xs={4}></Grid> */}
						{/* </Grid> */}
					</ListItem>
				))}
			</List>
		</>
	);
}

export function DatasetDetailPage() {
	// const [state, setState] = React.useState(0);
	const { id } = useParams();
	const [isModalDataDict, setIsModalDataDict] = React.useState(false);
	const location = useLocation();
	const currentUrl = `${window.location.origin}${location.pathname}${location.search}`;

	const dataset = datasets.find((item) => item.id === id);
	const {
		title,
		keyFeatures,
		paragraphs,
		useCases,
		dataDictionary,
		keywords,
		snowflakeUrl,
		detailsUrl,
	} = dataset;

	const handleClickEventDataDict = () => {
		setIsModalDataDict((prev) => !prev);
	};

	return (
		<Box bgcolor={'white'} py={10}>
			<Helmet key='dataset-card'>
				<title>{title}</title>
				<meta name='keywords' content={keywords.join(', ')} />
				<meta charSet='utf-8' name='description' content={paragraphs[0]} />
				<meta name='author' content='Oxari Team' />
				<link rel='canonical' href={detailsUrl} />
				<script type='application/ld+json'>
					{JSON.stringify({
						'@context': 'https://schema.org',
						'@type': 'Dataset',
						name: title,
						description: paragraphs[0],
						url: currentUrl,
						creator: {
							'@type': 'Organization',
							name: 'Oxari B.V.',
							url: 'https://oxari.io',
						},
						keywords: keywords,
						datePublished: '2024-12-07',
						license: 'Proprietary',
						distribution: [
							{
								'@type': 'DataDownload',
								encodingFormat: 'text/csv',
								name: 'CSV Format',
							},
							{
								'@type': 'DataDownload',
								encodingFormat: 'application/vnd.ms-excel',
								name: 'Excel Format',
							},
							{
								'@type': 'DataDownload',
								encodingFormat: 'API',
								name: 'API Access',
							},
						],
					})}
				</script>
			</Helmet>
			<Container>
				<Grid
					container
					direction={{ xs: 'column', sm: 'column', md: 'row' }}
					justifyContent='space-between'
					// spacing={10}
				>
					<Grid
						item
						xs={12}
						md={7}
						// paddingBottom={4}
						// sx={{
						// 	marginRight: {
						// 		xs: 0,
						// 		sm: 8,
						// 		md: 8,
						// 	},
						// }}
					>
						<Grid item xs={12} md={12}>
							<Box
								paddingTop={3}
								paddingBottom={3}
								sx={{ textAlign: { xs: 'center', sm: 'center', md: 'left' } }}
							>
								<Typography variant='fatHeader'>{title}</Typography>
							</Box>
						</Grid>
						{paragraphs.map((paragraph, i) => (
							<Grid item xs={12} mb={2} key={`datasetPara${i}`}>
								<Typography variant='body1' align='justify'>
									{paragraph}
								</Typography>
							</Grid>
						))}
						<Grid item xs={12}>
							<List>
								{useCases.map((elem, idx) => (
									<ListItem key={idx}>
										<ListItemIcon>
											<AddIcon />
										</ListItemIcon>
										<ListItemText>{elem}</ListItemText>
									</ListItem>
								))}
							</List>
						</Grid>
						<Grid item>
							<Accordion open={isModalDataDict} onClose={handleClickEventDataDict}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									id='panel1-header'
									sx={{ fontWeight: 'bold' }}
								>
									<Typography sx={{ marginRight: 'auto' }}>Data Dictionary</Typography>
									<Typography sx={{ color: 'text.secondary' }}>
										{isModalDataDict ? 'Close' : 'More info'}
									</Typography>
								</AccordionSummary>

								<AccordionDetails>
									<DataDictTable dataDict={dataDictionary} />
								</AccordionDetails>
							</Accordion>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						sx={
							{
								// backgroundColor: '#39393A',
								// color: 'white',
							}
						}
					>
						<Grid
							item
							sx={{
								borderRadius: '20px',
								backgroundColor: '#39393A',
								color: 'white',
								paddingLeft: 5,
								paddingTop: 3,
								paddingBottom: 3,
							}}
							mb={4}
						>
							<Typography variant='subtitle' p={1} lineHeight='60px'>
								Key features...
							</Typography>
							<List
								sx={{
									listStyleType: 'disc',
									paddingRight: 3,
								}}
							>
								{keyFeatures.map((item, i) => (
									<ListItem sx={{ display: 'list-item' }} key={`productList${i}`}>
										{item}
									</ListItem>
								))}
							</List>
						</Grid>
						<Grid item container justifyContent={'space-around'}>
							<ContactButton content='Request Data' />
							<TrySampleButton snowflakeUrl={snowflakeUrl} />
						</Grid>
					</Grid>
				</Grid>

				{/* <Modal open={isModal} onClose={handleClickEvent}>
					<>
						<ContactForm />
					</>
				</Modal> */}

				{/* TODO To be implemented */}
				{/* {title === 'Corporate Carbon Estimation' && <CCCMethods />} */}
			</Container>
		</Box>
	);
}
