import React from 'react';
import {
	Container,
	Grid,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Typography,
	TextField,
	Button,
	FormControlLabel,
	Checkbox,
	FormHelperText,
} from '@mui/material';

<Container maxWidth='md'>
	<Grid container>
		<Grid item md={3} />
		<Grid item xs='auto'>
			<Card sx={{ my: 2 }}>
				<form
					action='https://oxari.us21.list-manage.com/subscribe/post?u=6a0ae82cb8f30d17bb3110eea&id=945e664833&f_id=003...'
					method='post'
					id='mc-embedded-subscribe-form'
					name='mc-embedded-subscribe-form'
					target='_blank'
					noValidate
				>
					<CardHeader>
						<Typography variant='h5' component='h2'>
							Sign up for our Newsletter!
						</Typography>
						<Typography variant='body1'>
							Subscribe to our newsletter to get updated on news and product developments.
						</Typography>
					</CardHeader>
					<CardContent>
						<div id='mc_embed_signup_scroll'>
							<TextField
								required
								id='mce-EMAIL'
								name='EMAIL'
								label='Email Address'
								variant='outlined'
								helperText={<FormHelperText id='mce-EMAIL-HELPERTEXT'></FormHelperText>}
								fullWidth
								margin='normal'
							/>
							<TextField
								id='mce-FNAME'
								name='FNAME'
								label='First Name'
								variant='outlined'
								helperText={<FormHelperText id='mce-FNAME-HELPERTEXT'></FormHelperText>}
								fullWidth
								margin='normal'
							/>
							<TextField
								id='mce-LNAME'
								name='LNAME'
								label='Last Name'
								variant='outlined'
								helperText={<FormHelperText id='mce-LNAME-HELPERTEXT'></FormHelperText>}
								fullWidth
								margin='normal'
							/>
							<FormControlLabel
								control={<Checkbox value='agree' color='primary' />}
								label='I agree to the terms and conditions'
							/>
							<div id='mce-responses'>
								<div id='mce-error-response' style={{ display: 'none' }}></div>
								<div id='mce-success-response' style={{ display: 'none' }}></div>
							</div>
							<div style={{ position: 'absolute', left: '-5000px' }} aria-hidden='true'>
								<input
									type='text'
									name='b_6a0ae82cb8f30d17bb3110eea_945e664833'
									tabIndex='-1'
									value=''
									onChange={(ev) => ev}
								/>
							</div>
						</div>
					</CardContent>
					<CardActions>
						<Button type='submit' variant='contained' color='primary' fullWidth>
							Subscribe
						</Button>
					</CardActions>
				</form>
			</Card>
		</Grid>
		<Grid item md={3} />
	</Grid>
</Container>;

export default function NewsletterPage() {
	return (
		<>
			<Container maxWidth='md'>
				<Grid container>
					<Grid item md={3} />
					<Grid item xs='auto'>
						<Card sx={{ my: 2 }}>
							<form
								action='https://oxari.us21.list-manage.com/subscribe/post?u=6a0ae82cb8f30d17bb3110eea&id=945e664833&f_id=003...'
								method='post'
								id='mc-embedded-subscribe-form'
								name='mc-embedded-subscribe-form'
								target='_blank'
								noValidate
							>
								<CardHeader>
									<Typography variant='h5' component='h2'>
										Sign up for our Newsletter!
									</Typography>
									<Typography variant='body1'>
										Subscribe to our newsletter to get updated on news and product developments.
									</Typography>
								</CardHeader>
								<CardContent>
									<div id='mc_embed_signup_scroll'>
										<TextField
											required
											id='mce-EMAIL'
											name='EMAIL'
											label='Email Address'
											variant='outlined'
											helperText={<FormHelperText id='mce-EMAIL-HELPERTEXT'></FormHelperText>}
											fullWidth
											margin='normal'
										/>
										<TextField
											id='mce-FNAME'
											name='FNAME'
											label='First Name'
											variant='outlined'
											helperText={<FormHelperText id='mce-FNAME-HELPERTEXT'></FormHelperText>}
											fullWidth
											margin='normal'
										/>
										<TextField
											id='mce-LNAME'
											name='LNAME'
											label='Last Name'
											variant='outlined'
											helperText={<FormHelperText id='mce-LNAME-HELPERTEXT'></FormHelperText>}
											fullWidth
											margin='normal'
										/>
										<FormControlLabel
											control={<Checkbox value='agree' color='primary' />}
											label='I agree to the terms and conditions'
										/>
										<div id='mce-responses'>
											<div id='mce-error-response' style={{ display: 'none' }}></div>
											<div id='mce-success-response' style={{ display: 'none' }}></div>
										</div>
										<div style={{ position: 'absolute', left: '-5000px' }} aria-hidden='true'>
											<input
												type='text'
												name='b_6a0ae82cb8f30d17bb3110eea_945e664833'
												tabIndex='-1'
												value=''
												onChange={(ev) => ev}
											/>
										</div>
									</div>
								</CardContent>
								<CardActions>
									<Button type='submit' variant='contained' color='primary' fullWidth>
										Subscribe
									</Button>
								</CardActions>
							</form>
						</Card>
					</Grid>
					<Grid item md={3} />
				</Grid>
			</Container>
		</>
	);
}
