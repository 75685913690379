import React from 'react';
import { Container, Divider, Grid, Typography, Box } from '@mui/material';
import { TeamCard } from '../components/team_card';
import {
	aboutExecutiveBoard,
	aboutSales,
	aboutDev,
	aboutSustainability,
} from '../misc/text/about.js';

const TeamSection = ({ title, teamData }) => {
	return (
		<React.Fragment>
			<Grid item xs={12} pt={7}>
				<Divider
					sx={{
						'&::before, &::after': {
							borderColor: 'secondary.light',
						},
					}}
				>
					<Typography variant='h2' color='white'>
						{title}
					</Typography>
				</Divider>
			</Grid>
			<Grid container sx={{ pt: 4, pb: 8 }}>
				{teamData.map((el, i) => (
					<Grid key={el.name + 'team' + i} item xs={12} sm={6} md={3} p={2}>
						<TeamCard
							pic={el.pic}
							name={el.title}
							description={el.subtitle}
							contact_links={el.contact_links}
							achievements={el.achievements}
						/>
					</Grid>
				))}
			</Grid>
		</React.Fragment>
	);
};

export function OxariTeam() {
	return (
		<Box bgcolor='secondary.dark'>
			<Container>
				<Grid item xs={12} pt={7}>
					<Typography variant='h2' color='white'>
						Meet the team
					</Typography>
				</Grid>
				<TeamSection title='Co-Founders' teamData={aboutExecutiveBoard} />
				<TeamSection title='Business Development and Sales' teamData={aboutSales} />
				<TeamSection title='Method and Software Development' teamData={aboutDev} />
				<TeamSection title='Sustainability' teamData={aboutSustainability} />
			</Container>
		</Box>
	);
}
