import ForestIcon from '@mui/icons-material/Forest';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';

export const products = [
	{
		id: '0',
		title: 'Athena – ESG insights in minutes, not days',
		icon: AutoAwesomeIcon,
		list: [
			'Access a comprehensive ESG database, built from thousands of data points sourced from reports, websites, and more?',
			'Query ESG-related information using natural language?',
			'Understand the ESG strategies of any company and how they compare to competitors?',
			'Create a visual and comprehensive analysis backed by real data within minutes?',
		],
		paragraphs: [
			'The ESG Knowledge Platform features three integrated solutions: Athena DB, Athena AI, and Athena Custom Integrations—designed to meet all ESG-related needs.',
			'Athena aims to be the largest and most affordable ESG data, knowledge, and analytics platform on the market. Currently in development, a working prototype will be available soon. To learn more about Athena’s capabilities, check out our video.',
		],
		extra: '',
	},
	{
		id: '1',
		title: 'Corporate Carbon Data Explorer',
		icon: ForestIcon,
		list: [
			"Estimate your own or other companies' carbon emissions from past data?",
			'Fill gaps in your Scope 1-3 emission data?',
			'Evaluate the carbon emissions produced by your current investment portfolio?',
			'Identify frontrunners and laggards in reducing harmful emissions?',
			'Analyze decoupling between financial growth and emissions?',
			'Assess the climate compatibility of companies and build climate-neutral investment portfolios?',
			'Consider multiple policy and technology scenarios?',
		],
		paragraphs: [
			'The Oxari Corporate Carbon Data Explorer provides emission data, forecasts, temperature scores, and financial insights for over 50,000 companies through an API and an intuitive Carbon Explorer dashboard. As the first of its kind, the Oxari Carbon API empowers users to:',
			'1) Estimate emissions using widely available financial inputs (Balance Sheet, Financial Statement etc.) plus industry and country identifiers',
			'2) Obtain a temperature score based on technology and policy development scenarios',
			'By integrating state-of-the-art climate research, AI, and economic modeling, Oxari delivers a unique set of carbon data and scores, helping businesses measure and mitigate climate impacts effectively while maintaining a financial perspective.',
		],
		extra: '',
	},
	{
		id: '2',
		title: 'Custom Climate, Economic & ESG Dashboards',
		icon: SpaceDashboardIcon,
		list: [
			'Track your sustainability performance in a comprehensive, data-driven dashboard?',
			'Present and visualize your sustainability insights for customers and clients?',
		],
		paragraphs: [
			'As sustainability issues gain more traction in business, policy, and consumer behavior, presenting ESG performance and data effectively has become crucial.',
			'Accessible, clear, and visually appealing maps, graphs, and interactive tools increase engagement and strengthen client relationships. Our experts can develop a customized dashboard tailored to your specific needs and use cases.',
			'Below is an example of a dashboard we have developed for our clients.',
		],
		extra: '',
	},
];
