/* global dataLayer */
/* eslint no-unused-vars: 0 */

import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from 'react-helmet-async';

// property name: 'paellis', website: 'www.oxari.io'
// property name: 'prerelease', website: 'https://dev.oxari.io/paellis'

const container = document.getElementById('root');
const root = createRoot(container);
const helmetCtx = {};
root.render(
	<React.StrictMode>
		<HelmetProvider context={helmetCtx}>
			<App />
		</HelmetProvider>
	</React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
