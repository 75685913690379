const showAuthors = (authors, createdBy) => {
	// fallback to createdBy if authors is empty
	if (authors.length === 0) {
		return createdBy.fullname;
	}

	return authors.map((a) => a.fullname).join(' & ');
};

export { showAuthors };
