import rocket from '../../imgs/rocket.webp';
import dottedLine from '../../imgs/dotted_line.webp';
import gearsMlDesc from '../../imgs/gears_ml_desc.webp';

export const uniqueSPts = [
	{
		icon: rocket,
		width: '75px',
		height: '75px',
		title: 'Accuracy and Reliability',
		description:
			'Rigorously extracted and error-checked data compliant with frameworks such as SFDR.',
	},
	{
		icon: dottedLine,
		width: '75px',
		height: '75px',
		title: 'Domain-Specific Focus',
		description: 'Purposefully built with ESG and financial data in mind',
	},
	{
		icon: gearsMlDesc,
		width: '80px',
		height: '80px',
		title: 'RAG and NLQ capabilities',
		description:
			'Answers grounded in official source documents, minimizing “hallucinations” common with LLMs',
	},
];

export const testimonials = [
	{
		author: 'Asha Mehta, CIO of Global Delta Capital, Author of Power of Capital (2023)',
		text: 'Oxari is a unique innovator in the ESG data industry. Their machine learning-based approach effectively fills data gaps, and their tool is simple and flexible to use. The Oxari system’s precise, AI-driven predictions are very valuable for our work.',
		image:
			'https://uploads-ssl.webflow.com/6014a1bfd2d9f016dd962991/6014a1bfd2d9f000459629a7_GDC%20Logo22.svg',
		alt: 'coltura',
		width: '150px',
		height: '100px',
	},
	{
		author: 'Matthew Metz, CEO of Coltura',
		text: "I have had the pleasure of working with Oxari for more than three years and have been impressed by the team's ability to extract important insights from data as well as from the professionalism, accuracy, and deep specialized knowledge of the individual team members.",
		image: 'https://coltura.org/wp-content/uploads/2023/01/Logo_Cuadrado.png',
		alt: 'global-delta-capital',
		width: '100px',
		height: '100px',
	},
];
