import React from 'react';
import { Box, Container, Divider, Typography } from '@mui/material';
import { termsOfUseContent } from '../misc/text/termsofuse';

class TermsOfUsePage extends React.Component {
	render() {
		return (
			<Container maxWidth='md' sx={{ my: 5 }}>
				<Typography variant='h6' id='termsofuse-intro' mb={4}>
					By accessing this website you agree to be bound by these Terms of Use and agree that you
					are responsible for the agreement with any applicable local laws and regulations. If you
					disagree with these Terms of Use, you are prohibited from accessing this website and using
					the tools. The materials on this website are protected by copyright and trade mark law.
				</Typography>

				<Box my={4}>
					{termsOfUseContent.map((el, i) => (
						<Box key={'tosSection' + i}>
							<Divider>
								<Typography variant='h5' textAlign='center' fontWeight={600}>
									{el.title}
								</Typography>
							</Divider>
							<Box my={4}>
								{el.body.map((list, j) => {
									if (typeof list === 'string')
										return (
											<Typography key={'tosCtx' + j} paragraph>
												{list}
											</Typography>
										);
									return (
										<ul key={j}>
											{list.map((listitem, k) => (
												<li key={'tosPt' + k}>
													<Typography>{listitem}</Typography>
												</li>
											))}
										</ul>
									);
								})}
							</Box>
						</Box>
					))}
				</Box>
			</Container>
		);
	}
}

export default TermsOfUsePage;
