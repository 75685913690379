import React from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';

export default function BillingForm() {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			<Typography component='h1' variant='h5'>
				Billing Information
			</Typography>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						required
						fullWidth
						id='customer'
						label='Customer'
						name='customer'
						variant='outlined'
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						fullWidth
						name='contact'
						label='Contact Name'
						id='contactName'
						variant='outlined'
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						fullWidth
						id='department'
						label='Department'
						name='department'
						autoComplete='department/title'
						variant='outlined'
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						fullWidth
						id='telephone'
						label='Telephone'
						name='telephone'
						autoComplete='telephone'
						variant='outlined'
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						fullWidth
						id='email'
						label='Email'
						name='email'
						autoComplete='email'
						variant='outlined'
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						fullWidth
						id='address'
						label='Address'
						name='address'
						autoComplete='address'
						variant='outlined'
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						fullWidth
						id='city'
						label='City'
						name='city'
						autoComplete='city'
						variant='outlined'
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						fullWidth
						id='country'
						label='Country'
						name='country'
						autoComplete='country'
						variant='outlined'
					/>
				</Grid>
			</Grid>
		</Box>
	);
}
