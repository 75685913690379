import React, { useEffect, useState } from 'react';
import { Container, Divider, Typography, List } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import CorporateCarbonAPITermsOfUseMd from '../misc/text/terms_of_service_oxari_corporate_carbon_api.md';

const CustomTitle = ({ children }) => (
	<Typography variant='h5' mb={4} textAlign={'center'}>
		{children}
	</Typography>
);

const CustomSectionTitle = ({ children }) => {
	return (
		<Divider>
			<Typography variant='h5' fontWeight={600}>
				{children}
			</Typography>
		</Divider>
	);
};

const CustomOrderedList = ({ children, depth = 0 }) => (
	<List
		component='ol'
		sx={{
			listStyleType: depth === 0 ? 'lower-alpha' : 'lower-roman',
			paddingLeft: 2 + depth * 2,
			padding: 4,
		}}
	>
		{children}
	</List>
);

const CustomListItem = ({ children }) => (
	<Typography as='li' variant='body' sx={{ display: 'list-item' }}>
		{children}
	</Typography>
);

function CorporateCarbonAPITermOfUse() {
	const [markdown, setMarkdown] = useState('');

	useEffect(() => {
		fetch(CorporateCarbonAPITermsOfUseMd)
			.then((res) => res.text())
			.then((text) => setMarkdown(text));
	});

	return (
		<Container maxWidth='md' sx={{ my: 5 }}>
			<ReactMarkdown
				components={{
					h1: CustomTitle,
					h2: CustomSectionTitle,
					ol: CustomOrderedList,
					li: CustomListItem,
				}}
			>
				{markdown}
			</ReactMarkdown>
		</Container>
	);
}

export default CorporateCarbonAPITermOfUse;
