import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Grid, Typography, Box, List, ListItem } from '@mui/material';
import { MoreInfoButton, TrySampleButton } from '../pages/dataset_overiew';
import { ContactButton } from './contact_button';

export default function DatasetCard(props) {
	const {
		title,
		list,
		paragraphs,
		id,
		//extra
		snowflakeUrl,
		children,
	} = props;
	// const history = useHistory();

	console.log(id);
	return (
		<Box bgcolor={'white'} py={10}>
			<Helmet key='dataset-card'>
				<meta charSet='utf-8' name='description' content={paragraphs[0]} />
				<title>{title}</title>
			</Helmet>
			<Container>
				<Grid container paddingBottom={6}>
					<Grid item xs={12} md={12}>
						<Box
							paddingTop={3}
							paddingBottom={3}
							sx={{ textAlign: { xs: 'center', sm: 'center', md: 'left' } }}
						>
							<Typography variant='fatHeader'>{title}</Typography>
						</Box>
					</Grid>

					<Grid item xs={12} md={8} paddingBottom={4}>
						{paragraphs.map((paragraph, i) => (
							<Grid item xs={12} mb={2} key={`datasetPara${i}`}>
								<Typography
									variant='body1'
									sx={{
										marginRight: {
											xs: 0,
											sm: 8,
											md: 8,
										},
									}}
									align='justify'
								>
									{paragraph}
								</Typography>
							</Grid>
						))}
						<Grid item>{children}</Grid>
						<Grid item>
							<Box display={'flex'} gap={1}>
								<MoreInfoButton id={id} />
								<ContactButton content={'Request Data'} />
								<TrySampleButton snowflakeUrl={snowflakeUrl} />
							</Box>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						sx={{
							backgroundColor: '#39393A',
							borderRadius: '20px',
							paddingLeft: 5,
							paddingTop: 3,
							paddingBottom: 3,
							color: 'white',
						}}
					>
						<Typography variant='subtitle' p={1} lineHeight='60px'>
							Key features...
						</Typography>
						<List
							sx={{
								listStyleType: 'disc',
								paddingRight: 3,
							}}
						>
							{list.map((item, i) => (
								<ListItem sx={{ display: 'list-item' }} key={`productList${i}`}>
									{item}
								</ListItem>
							))}
						</List>
					</Grid>
				</Grid>

				<Box
					display={'flex'}
					flexDirection={'column'}
					alignItems={{ xs: 'center', md: 'flex-end' }}
					m={{ xs: 0, md: 4 }}
					gap={2}
				>
					<Box>
						<Typography variant='h6' display='inline-block' mr={2}>
							Do you have questions?
						</Typography>
						<ContactButton content={'Contact us'} />
					</Box>
				</Box>

				{/* TODO To be implemented */}
				{/* {title === 'Corporate Carbon Estimation' && <CCCMethods />} */}
			</Container>
		</Box>
	);
}
