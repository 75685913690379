import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Badge, Box, Container, Grid, Typography } from '@mui/material';
import ProductCard from '../components/product_card';
import { products } from '../misc/text/products';

export function ProductsPage() {
	const [state, setState] = React.useState(0);

	const handleProductCardOnClick = (productId) => {
		setState(productId);
	};

	const product = products.find((p) => p.id === state.toString());

	return (
		<Box>
			<Helmet key='products'>
				<meta charSet='utf-8' />
				<title>Oxari Products | Sustainability Intelligence Solutions</title>
				<meta
					name='description'
					content='Explore Oxari’s innovative Sustainability intelligence solutions: Temperature Scoring, Corporate Carbon Estimation, ESG Dashboards, and more. Empower your sustainability journey today.'
				/>
				<meta
					name='keywords'
					content='Carbon intelligence, Sustainability intelligence, ESG dashboards, corporate carbon estimation, temperature scoring, sustainable investments, Oxari products'
				/>
				<meta name='author' content='Oxari Team' />
				<link rel='canonical' href='https://oxari.io/en/products' />

				<meta
					property='og:title'
					content='Oxari Products | Sustainability Intelligence Solutions'
				/>
				<meta
					property='og:description'
					content='Discover Oxari’s suite of products designed to help investors gain transparency into corporate emissions, track market trends, and navigate regulatory risks effectively.'
				/>
				<meta property='og:type' content='website' />
				<meta property='og:url' content='https://oxari.io/en/products' />
				<meta property='og:image' content='https://oxari.io/static/products-page-thumbnail.jpg' />

				<meta name='twitter:card' content='summary_large_image' />
				<meta
					name='twitter:title'
					content='Oxari Products | Sustainability Intelligence Solutions'
				/>
				<meta
					name='twitter:description'
					content='Explore Oxari’s innovative Sustainability intelligence solutions and take control of your sustainability strategy today.'
				/>
				<meta name='twitter:image' content='https://oxari.io/static/products-page-thumbnail.jpg' />

				<script type='application/ld+json'>
					{JSON.stringify({
						'@context': 'https://schema.org',
						'@type': 'WebPage',
						name: 'Products | Oxari',
						url: 'https://oxari.io/en/products',
						description:
							'Explore Oxari’s innovative carbon intelligence solutions, including Temperature Scoring, Corporate Carbon Estimation, and ESG Dashboards.',
						publisher: {
							'@type': 'Organization',
							name: 'Oxari',
							logo: {
								'@type': 'ImageObject',
								url: 'https://oxari.io/static/logo.png',
								width: 250,
								height: 250,
							},
						},
					})}
				</script>
			</Helmet>

			<Container>
				<Box
					paddingTop={6}
					paddingBottom={3}
					sx={{ textAlign: { xs: 'center', sm: 'center', md: 'left' } }}
				>
					<Typography variant='pageHeader'>Sustainability Intelligence Solutions</Typography>
				</Box>

				<Box
					my={6}
					sx={{
						width: { md: '70%' },
					}}
				>
					<Typography
						variant='h4'
						sx={{
							textAlign: {
								xs: 'center',
								sm: 'left',
								md: 'left',
							},
						}}
					>
						All the tools you need to understand sustainability performance, carry out materiality
						assessments, review company policies, strategies, and compliance, compute new metrics,
						perform industry and peer level benchmarking, and much more.
					</Typography>
				</Box>

				<Grid container mb={6}>
					{products.map((product, i) => (
						<Grid item xs={6} sm={6} md={4} p={1} key={`productCard${i}`}>
							<Badge
								color='primary'
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								sx={{
									width: '100%',
									height: '100%',
									'& > span': {
										transform: 'scale(1) translate(15%, -50%)',
									},
								}}
							>
								<Box
									onClick={() => handleProductCardOnClick(product.id)}
									sx={{
										display: 'flex',
										alignItems: 'center',
										flexDirection: 'column',
										flex: '1 1 50%',
										borderRadius: '1.25rem',
										backgroundColor: parseInt(state) === i ? '#A1F8B4' : '#D9D9D9',
										minHeight: '180px',
										p: 2,
										color: 'black',
										textTransform: 'none',
										'&:hover': { backgroundColor: '#A1F8B4' },
									}}
								>
									<product.icon sx={{ fontSize: '3rem', margin: 1 }} />
									<Typography
										variant='h6'
										textAlign={'center'}
										maxWidth='300px'
										fontWeight={'600'}
										m='auto'
									>
										{product.title}
									</Typography>
								</Box>
							</Badge>
						</Grid>
					))}
				</Grid>
			</Container>

			<ProductCard
				id={product.id}
				title={product.title}
				list={product.list}
				paragraphs={product.paragraphs}
				extra={product.extra}
			/>
		</Box>
	);
}
