import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Typography, Grid, CardActionArea, Card, CardMedia } from '@mui/material';
import { Box, Container } from '@mui/system';

import { OxariTimeline } from '../components/timeline';
import { OxariTeam } from '../components/team';

export class AboutPage extends React.Component {
	render() {
		return (
			<Box>
				<Helmet key='about'>
					<meta charSet='utf-8' />
					<title>About Us | Oxari Team</title>
					<meta
						name='description'
						content='Discover the mission, vision, and team behind Oxari. We provide innovative solutions in sustainable investments, driven by cutting-edge technology and expert analysis.'
					/>
					<meta
						name='keywords'
						content='sustainability, ESG, sustainable investments, Oxari team, financial data, climate analysis, sustainable finance'
					/>
					<meta name='author' content='Oxari Team' />
					<link rel='canonical' href='https://oxari.io/en/about' />

					<meta property='og:title' content='About Us | Oxari Team' />
					<meta
						property='og:description'
						content='Learn more about Oxari’s mission to revolutionize sustainable investments. Meet our interdisciplinary team and explore our innovative solutions for a greener future.'
					/>
					<meta property='og:type' content='website' />
					<meta property='og:url' content='https://oxari.io/en/about' />
					<meta property='og:image' content='https://oxari.io/static/about-page-thumbnail.jpg' />

					<meta name='twitter:card' content='summary_large_image' />
					<meta name='twitter:title' content='About Us | Oxari Team' />
					<meta
						name='twitter:description'
						content='Explore Oxari’s mission, vision, and innovative approach to sustainable investments.'
					/>
					<meta name='twitter:image' content='https://oxari.io/static/about-page-thumbnail.jpg' />

					<script type='application/ld+json'>
						{JSON.stringify({
							'@context': 'https://schema.org',
							'@type': 'Organization',
							name: 'Oxari',
							url: 'https://oxari.io',
							logo: 'https://oxari.io/static/logo.png',
							description:
								'Revolutionizing sustainable investments through data-driven solutions and cutting-edge technology.',
							foundingDate: '2023',
							founders: [
								{
									'@type': 'Person',
									name: 'Founder Name',
								},
							],
							sameAs: [
								'https://twitter.com/oxari',
								'https://www.linkedin.com/company/oxari',
								'https://www.facebook.com/oxari',
							],
						})}
					</script>
				</Helmet>

				<Container>
					<Box paddingTop={6} paddingBottom={3}>
						<Typography variant='pageHeader'>About Us</Typography>
					</Box>

					<Box paddingBottom={3}>
						<Typography variant='h4' align='justify'>
							We are an interdisciplinary team of experts in processing, analyzing, and
							understanding data related to sustainability. Oxari provides services to companies and
							NGOs that need firmographic data such as raw ESG data and financial data to guide
							investment and policy for the benefit of the planet. Our international team brings
							together expertise in finance, economics, business, computer science, artificial
							intelligence, and sustainability.
						</Typography>
					</Box>

					<Grid container justifyContent='center'>
						<Grid
							item
							xs={12}
							md={6}
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Card
								sx={{
									width: 560,
									height: 315,
									margin: 5,
								}}
							>
								<CardActionArea>
									<CardMedia
										component='iframe'
										width='560'
										height='315'
										src='https://www.youtube.com/embed/aEAhsFDYA1E'
										title='YouTube video player'
										frameBorder='0'
										allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
										allowFullScreen
									/>
								</CardActionArea>
							</Card>
						</Grid>
					</Grid>
				</Container>
				<OxariTimeline />
				<OxariTeam />
			</Box>
		);
	}
}
