import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Container, Grid } from '@mui/material';
import pic from './../imgs/empty.webp';

const imageStyle = {
	borderStyle: 'solid',
	borderColor: 'darkgreen',
	borderWidth: '0.5rem',
	width: '100%',
};

export class PageNotFoundPage extends React.Component {
	render() {
		return (
			<>
				<Helmet>
					<title>404 - Page Not Found</title>
					<meta name='robots' content='noindex' />
					<meta name='description' content='This page could not be found.' />
				</Helmet>
				<Box bgcolor='background.light' height='100%'>
					<Container>
						<Grid container p={4}>
							<Grid item sm={3} />
							<Grid item>
								<h1 className='text-center'>404 Error</h1>
								<p className='text-center'>
									Oops! We could not find the page you were looking for...
								</p>
							</Grid>
							<Grid item sm={3} />
							<Grid item sm={3} />
							<Grid item>
								<img src={pic} alt='Not found' style={imageStyle} />
							</Grid>
							<Grid item sm={3} />
						</Grid>
					</Container>
				</Box>
			</>
		);
	}
}
