export const termsOfUseIntro = [
	{
		title: 'Terms of Use Oxari',
		body: [
			'By accessing this website you agree to be bound by these Terms of Use and agree that you are responsible for the agreement with any applicable local laws and regulations. If you disagree with these Terms of Use, you are prohibited from accessing this website and using the tools. The materials on this website are protected by copyright and trade mark law.',
		],
	},
];

export const termsOfUseContent = [
	{
		title: 'Usage',
		body: [
			'Permission is granted to temporarily download one copy of the materials on this website for personal, non-commercial transitory viewing only. This is the grant of license, not a transfer of title, and under this license you may not:',
			[
				['Modify or copy materials of this website'],
				['Use the materials for any commercial purpose or for any public display'],
				['Attempt to reverse engineer any software contained on this website'],
				[
					'Remove any copyright or other proprietary notations from the materials on this website and/or',
				],
				[
					`Transfer any material on this website to another person or 'mirror' the materials on any other server.`,
				],
			],
			'If you do not comply with the terms of this license, Oxari will terminate the license. Upon termination, rights to access this website are revoked and you should destroy and downloaded materials in your possession whether it is printed or in electronic form.',
		],
	},
	{
		title: 'Disclaimer',
		body: [
			`All the materials on this website are provided 'as is.' Oxari makes no warranties, may it be expressed or implied, therefor negates all other warranties. Furthermore, Oxari does not make any representations concerning the accuracy or reliability of the use of the material on this website or otherwise relating to such material or any sites linked to this website.`,
		],
	},
	{
		title: 'Limitations',
		body: [
			'Oxari or its suppliers will not be held accountable for any damages that may arise due to the use or inability to use the materials on this website. Even in case Oxari or a representative of Oxari has been notified, verbal or written, of the possibility of such damage, Oxari will not be held accountable.',
		],
	},
	{
		title: 'Revisions and Errata',
		body: [
			'Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology. For further information, visit www.allaboutcookies.org',
		],
	},
	{
		title: 'Links',
		body: [
			`Oxari has not reviewed all the links on this website and is not responsible for the contents of such linked websites. The presence of any link does not imply endorsement by Oxari of the website or views represented in linked material. The use of any linked website is at the user's own risk.`,
		],
	},
	{
		title: 'Terms of Use Modifications',
		body: [
			'Oxari may revise these Terms of Use at any time without prior notice. By using this website, you agree to be bound by the current version of the Terms of Use.',
		],
	},
];
