import { Button, Modal } from '@mui/material';
import ContactForm from './contact_form';
import { useState } from 'react';

export function ContactButton({ content }) {
	const [isModal, setIsModal] = useState(false);

	const handleClickEvent = () => {
		setIsModal((prev) => !prev);
	};
	return (
		<>
			<Button onClick={handleClickEvent} variant='green'>
				{content}
			</Button>
			<Modal open={isModal} onClose={handleClickEvent}>
				<>
					<ContactForm />
				</>
			</Modal>
		</>
	);
}
