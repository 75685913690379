import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useHistory } from 'react-router-dom';
import { Container, Typography, Grid, Box, Button, Link } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

export default function PostTemplete(props) {
	const history = useHistory();
	const { title, sub, content, picture, publishDate, author } = props;

	let reading_time = null;
	if (content) {
		const words_number = content.split(' ').length;
		reading_time = Math.ceil(words_number / 200) + 1;
	}

	const components = {
		code({ inline, className, children, ...props }) {
			const match = /language-(\w+)/.exec(className || '');
			return !inline && match ? (
				<SyntaxHighlighter
					style={a11yDark}
					showLineNumbers
					language={match[1]}
					PreTag='div'
					{...props}
				>
					{String(children).replace(/\n$/, '')}
				</SyntaxHighlighter>
			) : (
				<code
					className={className}
					style={{
						backgroundColor: '#2d2d2d',
						color: '#f8f8f2',
						padding: '2px 4px',
						borderRadius: '4px',
					}}
					{...props}
				>
					{children}
				</code>
			);
		},
		a: (props) => <Link {...props}>{props.children}</Link>,
		img: (props) => <img {...props} style={{ maxWidth: '100%', height: 'auto' }} alt='' />,
	};

	return (
		<Box marginTop={3}>
			<Container>
				<Button
					onClick={() => history.push('/blog')}
					variant='green'
					to='/blog'
					startIcon={<ArrowBackIcon />}
				>
					Back to all posts
				</Button>
			</Container>

			<Container sx={{ paddingTop: 3, overflow: 'hidden', width: '100%', height: '250px' }}>
				<img src={picture} alt={title?.toLowerCase()} width='100%' />
			</Container>

			<Container sx={{ paddingTop: 6 }}>
				<Grid container>
					<Grid item xs={12} md={4} paddingRight={4}>
						<Box paddingBottom={3}>
							<Typography variant='blogPostTitle'>{title}</Typography>
						</Box>
						<Box paddingBottom={3}>
							<Typography variant='blogPostSubTitle'>{sub}</Typography>
						</Box>
						<Box paddingBottom={2}>
							<Typography variant='h6'>By {author}</Typography>
						</Box>
						<Box paddingBottom={2}>
							<Typography variant='h6'>Published on {publishDate}</Typography>
						</Box>
						<Box paddingBottom={2}>
							<Typography variant='h6'>{reading_time} minutes read</Typography>
						</Box>
					</Grid>

					<Grid item xs={12} md={8}>
						<ReactMarkdown components={components}>{content}</ReactMarkdown>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}
