import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';

import { useTheme } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

import { BACKEND_URL } from '../components/constants';

const easeOutQuad = (t) => t * (2 - t);
const frameDuration = 1000 / 60;

function useCountUp({ endValue, duration = 1000, onComplete }) {
	const countTo = parseInt(endValue, 10);
	const [count, setCount] = useState(0);
	const [start, setStart] = useState(false);

	useEffect(() => {
		if (!start) {
			return;
		}
		let frame = 0;
		const totalFrames = Math.round(duration / frameDuration);
		const counter = setInterval(() => {
			frame++;
			const progress = easeOutQuad(frame / totalFrames);
			setCount(Math.floor(countTo * progress));

			if (frame === totalFrames) {
				if (onComplete) {
					onComplete();
				}
				clearInterval(counter);
			}
		}, frameDuration);
		return () => clearInterval(counter);
		// eslint-disable-next-line
	}, [start]);

	return { count, setStart };
}

export default function Figures() {
	const [totalCountries, setTotalCountries] = React.useState(0);
	const [companiesTotal, setCompaniesTotal] = React.useState(0);
	const theme = useTheme();
	const myRef = useRef();
	const [dataFetched, setDataFetched] = useState(false);

	const { count: totalCountriesCount, setStart: setTotalCountriesStart } = useCountUp({
		endValue: totalCountries,
	});

	const { count: companiesTotalCount, setStart: setCompaniesTenStart } = useCountUp({
		endValue: companiesTotal,
	});

	useEffect(() => {
		async function fetchData() {
			try {
				const headers = {
					accept: 'application/json',
				};

				const response = await axios.post(
					BACKEND_URL + '/companies/availability',
					{ years: 5 },
					{
						headers: headers,
					}
				);
				setTotalCountries(response.data.payload.availability.countries);
				setCompaniesTotal(response.data.payload.availability.total_count);

				setDataFetched(true);
			} catch (error) {
				console.error(error);
			}
		}

		fetchData();
	}, []);

	useEffect(() => {
		if (dataFetched) {
			const observer = new IntersectionObserver(
				([entry]) => {
					if (entry.isIntersecting) {
						setTotalCountriesStart(true);
						setCompaniesTenStart(true);
						observer.disconnect();
					}
				},
				{ threshold: 0.8 }
			);

			observer.observe(myRef.current);

			return () => {
				observer.disconnect();
			};
		}
	}, [dataFetched, setTotalCountriesStart, setCompaniesTenStart]);

	return (
		<>
			<Grid
				item
				lg={6}
				md={6}
				xs={12}
				display='inline-flex'
				justifyItems={'center'}
				alignItems='center'
				flexDirection='column'
				mt={5}
			>
				<Typography
					variant='h2'
					fontWeight={'bold'}
					fontSize={'5rem'}
					color={theme.palette.primary.main}
				>
					{companiesTotalCount}
				</Typography>
				<Typography variant='body1'>companies covered.</Typography>
			</Grid>

			<Grid
				item
				ref={myRef}
				lg={4}
				md={4}
				xs={12}
				display='inline-flex'
				justifyItems={'center'}
				alignItems='center'
				flexDirection='column'
				mt={5}
			>
				<Typography
					variant='h2'
					fontWeight={'bold'}
					fontSize={'5rem'}
					color={theme.palette.primary.main}
				>
					{totalCountriesCount}
				</Typography>
				<Typography variant='body1'>countries represented.</Typography>
			</Grid>
		</>
	);
}
