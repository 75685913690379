import React from 'react';
import { Box, Container, Divider, Typography } from '@mui/material';
import { privacyStatementContent } from '../misc/text/privacystatement';

class PrivacyStatementPage extends React.Component {
	render() {
		return (
			<Container maxWidth='md' sx={{ my: 5 }}>
				<Typography variant='h6' id='privacystatement-intro' mb={4}>
					This privacy policy will explain how Oxari uses the personal data we collect from you when
					you use our website.
				</Typography>
				<Box my={4}>
					{privacyStatementContent.map((el, i) => (
						<div key={'privacyStatement' + i}>
							<Divider>
								<Typography variant='h5' textAlign='center' fontWeight={600}>
									{el.title}
								</Typography>
							</Divider>
							<Box my={4}>
								{el.body.map((list, j) => {
									if (typeof list === 'string')
										return (
											<Typography key={'privacyCtx' + j} paragraph>
												{list}
											</Typography>
										);
									return (
										<ul key={'privacyCtx' + j}>
											{list.map((listitem, k) => (
												<li key={'privacyList' + k}>{listitem}</li>
											))}
										</ul>
									);
								})}
							</Box>
						</div>
					))}
				</Box>
			</Container>
		);
	}
}

export default PrivacyStatementPage;
