export const FAQ = [
	{
		title: 'What should I do if the platform is not working as expected?',
		body: [
			"If you encounter a software problem, have any kind of technical question, or want to suggest any functionality that you would like to see added, please send an email to 'support@oxari.io' and our team will make sure to review it as soon as possible.",
		],
	},
	{
		title: 'Where do I sign up for the Oxari account?',
		body: [
			"To sign up for an Oxari account, go to the home page of Oxari and click on the 'Sign In' button in the upper-right corner. After you are redirected to the Sign In form, choose the 'Don't have an account? Sign Up' option and provide the necessary information. You will be automatically assigned to a free annual subscription plan.",
		],
	},
	{
		title: 'How do I get my API key?',
		body: [
			"After you completed the registration process and can successfully access the Oxari Dashboard, you can navigate to the 'Account' tab using the left panel. There you can find your unique API key ready to be copied.",
		],
	},
	{
		title: 'How can I upgrade my plan?',
		body: [
			"Inside the Oxari Dashboard, navigate to the 'Manage Subscription' tab using the left panel. There you can not only identify your current subscription but choose a different subscription as well. You will be further asked to provide your 'Billing Information', 'Review', and 'Confirm'. Your new subscription will take effect immediately. ",
		],
	},
	{
		title: 'How can I cancel my plan?',
		body: [
			"Inside the Oxari Dashboard, navigate to the 'Manage Subscription' tab using the left panel. There you can identify your current subscription by the red 'Cancel Subscription' button at the bottom of the card. By clicking that button you will cancel your current plan and you will be switched to the 'Free Plan'.",
		],
	},
	{
		title: 'How can I change my provided information?',
		body: [
			"You can easily do that by navigating to the 'Settings' tab in the left panel inside the Oxari Dashboard.",
		],
	},
	{
		title: 'What should I do if I forgot my password?',
		body: [
			"If you do not remember your password anymore, just click on the 'Forgot Password?' option below the Sign in form. We recommend you use a password manager so you avoid this problem in the future.",
		],
	},
	{
		title: 'How does the credit system work?',
		body: [
			"Each annual plan has a budget assigned to it. You can see your current budget in the upper-right corner on the Oxari Dashboard, or, in more detail, on the 'Billing' page. Every time you access information, be it from the dashboard, or using the API, you consume some credits from your total budget. You can check the 'Documentation' page to inspect the exact cost of the information you need.",
		],
	},
	{
		title: 'What do I have to do if I ran out of credits?',
		body: [
			"If you are out of credits, but you did not gather all the data you need, you can get new credits by changing your current subscription. If you are concerned about gathering new data once it becomes available, don't worry, you will be automatically billed and get the same amount of credits every year once new yearly data becomes available.",
		],
	},
	{
		title: 'What kind of products and services does Oxari offer?',
		body: [
			'Oxari offers custom, as well as out-of-the-box-ready services with a strong focus on providing and analysing environmental and financial data of global public companies. You can check the current and planned products on: https://www.oxari.io/products',
		],
	},
	{
		title: 'How precise are the Oxari emission predictions?',
		body: [
			"At Oxari, we try to provide data estimations as accurate as possible. However, as with any estimations, there is room for error. We openly provide our performance analysis so you can construct your own opinion. You can have a look at the Benchmarks on the 'Corporate Carbon Estimation' tab at: https://www.oxari.io/products",
		],
	},
	{
		title: 'How is data quality ensured?',
		body: [
			'Our data comes from reliable sources, such as carbon reports for the carbon emissions data, and 12data for the financial data. We also go through the process of validating any used data using available 3rd party sources.',
		],
	},
];
