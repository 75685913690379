import React from 'react';
import { useHistory, NavLink as CustomNavLink } from 'react-router-dom';
import {
	AppBar,
	Drawer,
	Box,
	IconButton,
	Button,
	Link,
	Divider,
	List,
	ListItem,
	ListItemText,
	Container,
	useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../imgs/oxari.webp';
import logoSquare from '../imgs/oxari_icon.svg';
import {
	LUCRUM_URL,
	// BACKEND_URL
} from './constants';

const navPages = [
	{ label: 'Solutions', path: '/solutions', type: 'internal' },
	{ label: 'Data', path: '/datasets', type: 'internal' },
	{ label: 'Pricing', path: '/pricing', type: 'internal' },
	{ label: 'Blog', path: '/blog', type: 'internal' },
	{ label: 'About', path: '/about', type: 'internal' },
	// { label: 'Docs', path: BACKEND_URL + '/redoc', type: 'external' },
];

const specialPages = [
	{ label: 'Athena', path: 'https://athena.oxari.io', type: 'external' },
	{ label: 'Carbon Explorer', path: LUCRUM_URL + '/signin', type: 'external' },
];

function NavbarLink({ page }) {
	const theme = useTheme();

	const isExternal = page.type === 'external';
	const linkProps = isExternal
		? { href: page.path, target: '_blank', rel: 'noopener noreferrer' }
		: { to: page.path, component: CustomNavLink };

	return (
		<Link
			{...linkProps}
			underline='none'
			sx={{
				color: 'secondary.dark',
				textDecoration: 'none',
				fontSize: '20px',
				fontWeight: 500,
				paddingX: { xs: 1, lg: 2, xl: 3 },
				marginY: 'auto',
				'&:hover': {
					backgroundColor: theme.palette.primary.light,
					borderRadius: 20,
				},
			}}
		>
			{page.label}
		</Link>
	);
}

function OxariLogo() {
	const history = useHistory();
	return (
		<Box
			component='img'
			src={logo}
			alt='oxari-logo'
			sx={{ cursor: 'pointer', height: 90 }}
			onClick={() => history.push('/')}
		/>
	);
}

function OxariLogoSquare() {
	const history = useHistory();
	return (
		<Box
			component='img'
			src={logoSquare}
			alt='oxari-logo'
			sx={{ cursor: 'pointer', height: 60 }}
			onClick={() => history.push('/')}
		/>
	);
}

export default function ResponsiveAppBar() {
	const [drawerOpen, setDrawerOpen] = React.useState(false);

	const toggleDrawer = (open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setDrawerOpen(open);
	};

	return (
		<AppBar position='static' sx={{ bgcolor: 'background.default', padding: 1, zIndex: 1000 }}>
			<Container maxWidth='xl'>
				<Box
					display={'flex'}
					flexWrap='nowrap'
					alignItems='center'
					justifyContent={'center'}
					position='relative'
				>
					<IconButton
						onClick={toggleDrawer(true)}
						color='inherit'
						sx={{
							display: { xs: 'inline-flex', md: 'none' },
							position: 'absolute',
							left: 0,
							top: '50%',
							transform: 'translateY(-50%)',
						}}
					>
						<MenuIcon />
					</IconButton>
					<Box
						display='inline-flex'
						flexGrow={{ xs: 1, md: 0 }}
						justifyContent={{ xs: 'center', md: 'left' }}
					>
						<OxariLogo />
					</Box>
					<Box
						display={{ xs: 'none', md: 'flex' }}
						flex='1 0 auto'
						justifyContent={'flex-start'}
						paddingRight={'auto'}
						paddingLeft={2}
					>
						{/* <Box > */}
						{navPages.map((page, idx) => (
							<NavbarLink key={idx} page={page} />
						))}
						{/* </Box> */}
					</Box>

					<Box display={{ xs: 'none', md: 'flex' }} gap={1}>
						{specialPages.map((page, idx) => (
							<Button
								component={Link}
								key={'sp' + idx}
								href={page.path}
								target='_blank'
								rel='noopener noreferrer'
								variant='contained'
								sx={{
									borderRadius: 1.5,
									minWidth: '150px',
									width: 'auto',
									paddingX: 2,
									marginY: 'auto',
									fontSize: '20px',
									fontWeight: 'h4.fontWeight',
									textWrap: 'nowrap',
									color: 'background.plain',
									bgcolor: 'secondary.dark',
									textTransform: 'none',
									textDecoration: 'none',
									border: 2,
									borderColor: 'secondary.dark',
									'&:hover': {
										backgroundColor: 'background.plain',
										color: 'secondary.dark',
									},
								}}
							>
								{page.label}
							</Button>
						))}
					</Box>
				</Box>
			</Container>
			<Drawer
				anchor='left'
				open={drawerOpen}
				onClose={toggleDrawer(false)}
				sx={{ display: { xs: 'block', md: 'none' } }}
			>
				<Box sx={{ ml: 2, mt: 5 }}>
					<OxariLogoSquare />
				</Box>
				<Box
					sx={{ width: 250 }}
					role='presentation'
					onClick={toggleDrawer(false)}
					onKeyDown={toggleDrawer(false)}
				>
					<List>
						{navPages.map((page, idx) => (
							<ListItem button key={idx}>
								{page.type === 'external' ? (
									<Link
										href={page.path}
										target='_blank'
										rel='noopener noreferrer'
										sx={{ color: 'secondary.dark', textDecoration: 'none' }}
									>
										<ListItemText primary={page.label} />
									</Link>
								) : (
									<CustomNavLink
										to={page.path}
										style={{ textDecoration: 'none', color: 'secondary.dark' }}
									>
										<ListItemText primary={page.label} />
									</CustomNavLink>
								)}
							</ListItem>
						))}
					</List>
					<Divider />
					<List>
						{specialPages.map((page, idx) => (
							<ListItem key={idx}>
								<Link
									href={page.path}
									target='_blank'
									rel='noopener noreferrer'
									sx={{ color: 'secondary.dark', textDecoration: 'none' }}
								>
									<ListItemText primary={page.label} />
								</Link>
							</ListItem>
						))}
					</List>
				</Box>
			</Drawer>
		</AppBar>
	);
}
