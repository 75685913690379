import React from 'react';
import { Typography } from '@mui/material';
import { styled, keyframes } from '@mui/system';

const DURATION = 4;

const tickerFullAnimation = keyframes`
0% {
  transform: translateY(100%);
  opacity: 0;
}
20% {
  transform: translateY(0);
  opacity: 0.9
}
70% {
  transform: translateY(0);
  opacity: 1;
}
100% {
  transform: translateY(-200%);
  opacity: 0;
}
`;

const VerticalTickerWrapper = styled('div')`
	display: flex;
`;

const VerticalTickerContainer = styled('div', { shouldForwardProp: (prop) => prop })(
	({ theme, variant, fontWeight }) => ({
		...theme.typography[variant],
		fontWeight,
		position: 'relative',
		width: '100%',
		height: theme.typography[variant].fontSize,
		overflow: 'hidden',
	})
);

const VerticalTickerList = styled('ul', { shouldForwardProp: (prop) => prop })(() => ({
	position: 'relative',
	listStyle: 'none',
	padding: 0,
	height: '100%',
	margin: 0,
}));

const VerticalTickerWord = styled('li', { shouldForwardProp: (prop) => prop })(({ active }) => ({
	position: 'absolute',
	display: active === 'true' ? 'initial' : 'none',
	animation: `${tickerFullAnimation} ${DURATION}s infinite ease-in-out`,
}));

const VerticalTicker = ({ items, staticSuffix = '', variant, fontWeight }) => {
	const [idx, setIdx] = React.useState(0);
	React.useEffect(() => {
		const tickerInterval = setInterval(() => {
			const activeIdx = idx + 1 === items.length ? 0 : idx + 1;
			setIdx(activeIdx);
		}, DURATION * 1000);

		return () => clearInterval(tickerInterval);
	}, [idx, items.length]);

	return (
		<VerticalTickerWrapper>
			<Typography variant={variant} fontWeight={fontWeight} mr={1}>
				{staticSuffix}
			</Typography>
			<VerticalTickerContainer variant={variant} fontWeight={fontWeight}>
				<VerticalTickerList>
					{items.map((item, i) => (
						<VerticalTickerWord
							key={`verticalTickerWord${i}`}
							variant={variant}
							fontWeight={fontWeight}
							active={(i === idx).toString()}
						>
							{item}
						</VerticalTickerWord>
					))}
				</VerticalTickerList>
			</VerticalTickerContainer>
		</VerticalTickerWrapper>
	);
};

export default VerticalTicker;
