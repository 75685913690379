import React from 'react';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Grid,
	Container,
	Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FAQ } from '../misc/text/faq';

export class FAQPage extends React.Component {
	render() {
		return (
			<Container>
				<Typography variant='h2' p={6}>
					FAQs
				</Typography>

				<Grid container justifyContent='center'>
					{FAQ.map((faq, idx) => (
						<Grid item xs={12} md={12} p={2} key={idx}>
							<Accordion box-shadow={0}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<Typography variant='subtitle' p={2}>
										{faq.title}
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography variant='h4' p={2}>
										{faq.body}
									</Typography>
								</AccordionDetails>
							</Accordion>
						</Grid>
					))}
				</Grid>
			</Container>
		);
	}
}
