import React from 'react';
import { Helmet } from 'react-helmet-async';
import { withRouter } from 'react-router-dom';
import { useTheme, styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import { Box, Container, Grid, Typography, Button, Modal, Link } from '@mui/material';

import TestimonialsCarousel from '../components/testimonials_card';
// import Newsfeed from '../components/newsfeed';
import ContactForm from '../components/contact_form';
import VerticalTicker from '../components/vertical_ticker.js';
import { uniqueSPts } from '../misc/text/home.js';

import chatimg from '../imgs/athena_conversation.png';
import vizimg from '../imgs/athena_visualization.png';
import sdg8 from '../imgs/sdgs/E_WEB_INVERTED_08.png';
import sdg11 from '../imgs/sdgs/E_WEB_INVERTED_11.png';
import sdg13 from '../imgs/sdgs/E_WEB_INVERTED_13.png';
import sdg17 from '../imgs/sdgs/E_WEB_INVERTED_17.png';

function HomeSection({ children, title, titleColor, sectionBgColor = 'white', ...otherProps }) {
	return (
		<Box
			bgcolor={sectionBgColor}
			display='flex'
			alignItems='center'
			py={{ xs: 6, md: 12 }}
			px={{ xs: 4, md: 2, lg: 0 }}
		>
			<Container>
				{title && (
					<Typography variant='h4' color={titleColor} fontWeight={'bold'} mb={4}>
						{title}
					</Typography>
				)}
				<Grid container {...otherProps}>
					{children}
				</Grid>
			</Container>
		</Box>
	);
}

function DoubleColumnSection({
	children,
	title,
	titleColor,
	description,
	sectionBgColor,
	isLeft = true,
}) {
	return (
		<Box
			bgcolor={sectionBgColor}
			display='flex'
			flexDirection={{ xs: 'column', md: 'row' }}
			alignItems='center'
			justifyContent={'space-evenly'}
		>
			<Container style={{ display: 'inline-flex' }}>
				<Grid container spacing={6}>
					<Grid item md={5} order={isLeft ? 1 : 12}>
						{title && (
							<Typography variant='h4' color={titleColor} fontWeight={'bold'} mb={4}>
								{title}
							</Typography>
						)}
						{description && (
							<Typography variant='p' color={titleColor} fontWeight={'bold'} mb={4}>
								{description}
							</Typography>
						)}
					</Grid>
					<Grid item md={7} order={isLeft ? 12 : 1}>
						{children}
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}

const HeroBG = styled(Box)({
	minHeight: '80vh',
	margin: 'auto',
	display: 'flex',
	alignItems: 'center',
});

const textShine = keyframes`
  0% {
    background-position: 50% 0%;
    }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
`;

const GlowingTxt = styled(Typography)({
	width: '100%',
	margin: 'auto',
	textAlign: 'center',
	marginBottom: '1rem',
	span: {
		background: 'linear-gradient(180deg,  #D7D7D7, #868683, #39393A 75%)',
		webkitBackgroundClip: 'text',
		backgroundClip: 'text',
		webkitTextFillColor: 'transparent',
		textFillColor: 'transparent',
		backgroundSize: '200% 200%',
		animation: `${textShine} 5s ease infinite`,
	},
});

function HeroSection() {
	const theme = useTheme();

	return (
		<HeroBG>
			<Container>
				<Box
					display={'inline-flex'}
					width='100%'
					flexDirection={{ xs: 'column' }}
					justifyContent={{ xs: 'center' }}
					alignItems={'center'}
					minHeight={'80vh'}
					gap={5}
				>
					<Box>
						<Typography
							variant='h4'
							fontWeight={'500'}
							color={theme.palette.primary.bright_green}
							mb={2}
						>
							Trusted Data and Insights
						</Typography>
						<Typography variant={'h1'} fontWeight={'700'} color='black' mb={2}>
							Sustainability analysis
							<br />
							at your fingertips
						</Typography>
						<VerticalTicker
							items={['Financials', 'Emissions', 'Waste', 'Water', 'Biodiversity'].sort(
								() => Math.random() - 0.5
							)}
							variant='h2'
							fontWeight='500'
						/>
					</Box>
					<Box
						display={'flex'}
						flexDirection={'column'}
						alignItems={'center'}
						justifyContent={'center'}
					>
						<Link
							component={Button}
							href={'https://athena.oxari.io'}
							target='_blank'
							rel='noopener noreferrer'
							sx={{
								fontSize: theme.typography.h3,
								// textTransform: 'Capitalize',
								padding: 2,
								paddingRight: 5,
								paddingLeft: 5,
								mb: 1,
								borderRadius: 2,
								textDecoration: 'none',
								background: theme.palette.primary.button_and_highlights,
								color: theme.palette.secondary.dark,
								'&:hover': {
									background: theme.palette.primary.main,
									color: theme.palette.secondary.dark,
								},
							}}
						>
							Explore Athena
						</Link>
						<Box>
							<Typography
								variant='subtitle2'
								sx={{
									[theme.breakpoints.down('xs')]: {
										color: theme.palette.secondary.dark,
									},
									[theme.breakpoints.up('sm')]: {
										color: theme.palette.secondary.light,
									},
								}}
							></Typography>
						</Box>
					</Box>
				</Box>
				<GlowingTxt variant='h2'>
					<span>▼</span>
				</GlowingTxt>
			</Container>
		</HeroBG>
	);
}

function USPsBox({ ctx }) {
	const imgW = ctx.width ?? '50px';
	const imgH = ctx.height ?? '50px';

	return (
		<Grid item md={4} display={'flex'} alignItems={'center'} my={{ xs: 2, md: 0 }}>
			<Box width={imgW} height={imgH} borderRadius={0.5}>
				<img width={imgW} height={imgH} src={ctx.icon} alt={ctx.description} />
			</Box>
			<Box>
				<Typography variant='h6' fontWeight={'700'} mx={1}>
					{ctx.title}
				</Typography>
				<Typography variant='h6' lineHeight={{ xs: 1.2, md: 1.4 }} fontWeight={'500'} mx={1}>
					{ctx.description}
				</Typography>
			</Box>
		</Grid>
	);
}

function Home() {
	const [isModal, setIsModal] = React.useState(false);
	const handleClickEvent = () => {
		setIsModal((prev) => !prev);
	};

	const theme = useTheme();

	return (
		<Box sx={{ flexGrow: 1, paddingTop: '0px', mt: '0px', pt: '0px' }}>
			<Helmet key='home'>
				<title>Oxari - Home</title>
				<link rel='canonical' href='https://oxari.io/en' />
			</Helmet>
			{/* Title section */}
			<HeroSection />

			{/* Showcase section */}
			<HomeSection title='' sectionBgColor={theme.palette.secondary.dark}>
				<DoubleColumnSection
					title={'Removing Analysis Barriers'}
					titleColor={theme.palette.secondary.light}
					description={
						"Effortlessly access data and perform tasks such as benchmarking or materiality assessments using Athena's chat interface."
					}
				>
					<img
						style={{
							borderRadius: '1rem',
							boxShadow: `4px 4px 12px 2px ${theme.palette.background.athena_dark}`,
							width: '100%',
							height: 'auto',
						}}
						src={chatimg}
						alt='athena conversation'
					/>
				</DoubleColumnSection>
			</HomeSection>

			<HomeSection title='' sectionBgColor={theme.palette.secondary.light}>
				<DoubleColumnSection
					title={'Results in Seconds, Not Days'}
					titleColor={theme.palette.secondary.dark}
					description={
						'Athena delivers instant qualitative and quantitative analysis as concise text, tables, and graphs, eliminating repetitive manual work.'
					}
					isLeft={false}
				>
					<img
						style={{
							borderRadius: '1rem',
							boxShadow: `-4px 4px 12px 2px ${theme.palette.background.athena_dark}`,
							width: '100%',
							height: 'auto',
						}}
						src={vizimg}
						alt='athena visualization'
					/>
				</DoubleColumnSection>
			</HomeSection>

			<HomeSection
				sectionBgColor={theme.palette.secondary.light}
				title='Athena vs Regular LLMs'
				titleColor={theme.palette.secondary.dark}
			>
				{uniqueSPts.map((el, i) => (
					<USPsBox key={`USPS${i}`} ctx={el} />
				))}
			</HomeSection>

			{/* Partners */}
			<HomeSection
				display='flex'
				justifyContent='center'
				alignItems='center'
				sectionBgColor='white'
				title='Building trust with our partners'
			>
				<Grid item>
					<TestimonialsCarousel />
				</Grid>
			</HomeSection>

			{/* News */}
			{/* <Box>
				<Newsfeed />
			</Box> */}

			{/* Contact */}
			<HomeSection
				alignItems='center'
				sectionBgColor={theme.palette.primary.light}
				title='Every investment has an impact'
				titleColor={theme.palette.secondary.dark}
			>
				<Grid item md={6}>
					<Typography variant='body1'>
						We want to help both small and big investors to evaluate companies and use their
						influence to create the sustainable businesses of tomorrow.
					</Typography>
					<Typography variant='body1' my={3}>
						Oxari contributes to the following sustainable development goals (SDGs):
					</Typography>
					{/* SDG Images Row */}
					<Grid container spacing={2}>
						<Grid item xs={6} sm={2} md={3}>
							<img src={sdg8} alt='SDG 8' width='100%' />
						</Grid>
						<Grid item xs={6} sm={2} md={3}>
							<img src={sdg11} alt='SDG 11' width='100%' />
						</Grid>
						<Grid item xs={6} sm={2} md={3}>
							<img src={sdg13} alt='SDG 13' width='100%' />
						</Grid>
						<Grid item xs={6} sm={2} md={3}>
							<img src={sdg17} alt='SDG 17' width='100%' />
						</Grid>
					</Grid>
				</Grid>
				<Grid item md={6} width={'100%'}>
					<Box
						display='flex'
						mx='auto'
						justifyContent='center'
						flexDirection='column'
						alignItems='center'
					>
						<Typography variant='body1' my={3} textAlign={{ md: 'center' }}>
							Do you want to use our data or find out more?
						</Typography>
						<Button
							variant='contained'
							sx={{
								background: '#39393A',
								color: '#ffffff',
								textTransform: 'none',
								'&:hover': {
									backgroundColor: '#fff',
									borderColor: '#39393A',
									color: '#39393A',
								},
							}}
							onClick={handleClickEvent}
						>
							Contact us
						</Button>
					</Box>
				</Grid>
			</HomeSection>

			<Modal open={isModal} onClose={handleClickEvent}>
				<>
					<ContactForm />
				</>
			</Modal>
		</Box>
	);
}

export const HomePage = withRouter(Home);
