import { PUBLIC_PATH } from '../../components/constants';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import Co2Icon from '@mui/icons-material/Co2';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
export const datasets = [
	{
		id: '0',
		detailsUrl: `${PUBLIC_PATH}/dataset/0`,
		title: 'Corporate Financial Health',
		icon: BusinessCenterIcon,
		keyFeatures: [
			'Comprehensive Financial Metrics: Covers crucial indicators like revenue, profitability, debt ratios, and asset values, providing an in-depth financial view of each company.',
			'Global Coverage: Data on over 50,000 companies worldwide, allowing broad industry and geographic comparisons.',
			'Up-to-Date and Reliable Data: Aggregated from trusted sources and continually updated to ensure accuracy and relevance in fast-evolving markets.',
		],
		paragraphs: [
			'Oxari’s Financial Data Product provides key financial metrics on over 50,000 companies worldwide, delivering a comprehensive view of each company’s financial health. The dataset is curated for precision and includes vital indicators such as revenue, profit margins, debt ratios, and asset values, offering a complete financial profile essential for investment and risk analysis. This robust financial data enables users to assess company stability, evaluate risk exposure, and inform long-term investment strategies.',
			'Oxari’s Financial Data Product is essential for any professional seeking comprehensive, reliable financial data to support thorough risk assessment, informed investment decisions, and strategic planning.',
		],
		extra: 'EXTRA',
		snowflakeUrl:
			'https://app.snowflake.com/marketplace/listing/GZSYZRC7E4/oxari-financial-data-for-50-000-companies-since-2017',
		useCases: [
			'ESG Investment Analysis: Investors can integrate financial metrics with sustainability data to gain a holistic view of each company’s overall performance and stability, supporting sustainable investment decisions.',
			'Risk Assessment: Risk managers leverage this data to evaluate financial exposure and predict potential vulnerabilities, offering insights into long-term viability and risk resilience.',
			'Corporate Benchmarking: Companies use these financial indicators to benchmark against industry peers, assessing competitive standing and informing strategic decisions to strengthen financial stability.',
			'Economic Impact Analysis: Analysts assess the economic impact of various sectors and companies, identifying trends and growth potential, which supports market analysis and economic modeling.',
		],
		keywords: [
			'financial data',
			'investment',
			'corporate health',
			'risk assessment',
			'global companies',
		],
		dataDictionary: [
			{
				varName: 'year',
				description: 'The year of the data record.',
				dataType: 'integer',
				example: 2020,
			},
			{
				varName: 'country_code',
				description: 'The country code of the company’s headquarters.',
				dataType: 'string',
				example: 'NLD',
			},
			{
				varName: 'ticker',
				description: 'The stock ticker symbol of the company.',
				dataType: 'string',
				example: '0O26.XLON',
			},
			{
				varName: 'currency',
				description: 'The currency used for the company’s financial data.',
				dataType: 'string',
				example: 'USD',
			},
			{
				varName: 'exchange',
				description: 'The stock exchange where the company is listed.',
				dataType: 'string',
				example: 'XLON',
			},
			{
				varName: 'exchange_timezone',
				description: 'The timezone of the stock exchange.',
				dataType: 'string',
				example: 'Europe/London',
			},
			{
				varName: 'name',
				description: 'The full name of the company.',
				dataType: 'string',
				example: 'Heineken N.V.',
			},
			{
				varName: 'symbol',
				description: 'The exchange symbol used to represent the company.',
				dataType: 'string',
				example: '0O26',
			},
			{
				varName: 'industry_name',
				description: 'The industry classification of the company.',
				dataType: 'string',
				example: 'Beverages—Brewers',
			},
			{
				varName: 'region',
				description: 'The geographical region of the company’s operations.',
				dataType: 'string',
				example: 'Europe',
			},
			{
				varName: 'sector_name',
				description: 'The business sector the company operates in.',
				dataType: 'string',
				example: 'Consumer Defensive',
			},
			{
				varName: 'sub_region',
				description: 'The sub-region within the geographical area.',
				dataType: 'string',
				example: 'Western Europe',
			},
			{
				varName: '5_year_average_dividend_yield',
				description: 'The 5-year average dividend yield.',
				dataType: 'float',
				example: 1.38,
			},
			{
				varName: 'accounts_payable',
				description: 'The company’s total accounts payable.',
				dataType: 'integer',
				example: 1299023660,
			},
			{
				varName: 'accounts_receivable',
				description: 'The total value of receivables from customers.',
				dataType: 'integer',
				example: 3121586215,
			},
			{
				varName: 'additional_paid_in_capital',
				description: 'Capital raised in excess of the stock’s par value.',
				dataType: 'integer',
				example: 161154,
			},
			{
				varName: 'avg_10_volume',
				description: 'The average trading volume over the last 10 days.',
				dataType: 'integer',
				example: 266737,
			},
			{
				varName: 'avg_90_volume',
				description: 'The average trading volume over the last 90 days.',
				dataType: 'integer',
				example: 665259,
			},
			{
				varName: 'basic_shares_outstanding',
				description: 'The number of basic shares outstanding.',
				dataType: 'integer',
				example: 665259138,
			},
			{
				varName: 'beta',
				description: 'The company’s beta value, a measure of volatility relative to the market.',
				dataType: 'float',
				example: 0.664247,
			},
			{
				varName: 'capital_expenditures',
				description: 'Total capital expenditures by the company.',
				dataType: 'integer',
				example: -1561370965,
			},
			{
				varName: 'common_dividends',
				description: 'The dividends paid to holders of common stock.',
				dataType: 'integer',
				example: -937284865,
			},
			{
				varName: 'common_stock',
				description: 'The total value of common stock issued.',
				dataType: 'integer',
				example: 1065569230,
			},
			{
				varName: 'common_stock_issuance',
				description: 'The value of new shares issued.',
				dataType: 'integer',
				example: 12712865,
			},
			{
				varName: 'cost_of_goods',
				description: 'The direct costs related to the production of goods.',
				dataType: 'integer',
				example: 14388651750,
			},
			{
				varName: 'current_assets.cash',
				description: 'The company’s cash on hand.',
				dataType: 'integer',
				example: 4622860000,
			},
			{
				varName: 'current_assets.inventory',
				description: 'The value of the company’s inventory.',
				dataType: 'integer',
				example: 2262889970,
			},
			{
				varName: 'current_liabilities.accounts_payable',
				description: 'The total of accounts payable in current liabilities.',
				dataType: 'integer',
				example: 4233384045,
			},
			{
				varName: 'current_liabilities.total_current_liabilities',
				description: 'The total value of all current liabilities.',
				dataType: 'integer',
				example: 12560310620,
			},
			{
				varName: 'day_200_ma',
				description: 'The 200-day moving average of the company’s stock price.',
				dataType: 'float',
				example: 92.71198665,
			},
			{
				varName: 'day_50_ma',
				description: 'The 50-day moving average of the company’s stock price.',
				dataType: 'float',
				example: 94.21162355,
			},
			{
				varName: 'ebit',
				description: 'The company’s earnings before interest and taxes.',
				dataType: 'integer',
				example: 795131920,
			},
			{
				varName: 'ebitda',
				description: 'The earnings before interest, taxes, depreciation, and amortization.',
				dataType: 'integer',
				example: 4080829665,
			},
			{
				varName: 'enterprise_value',
				description: 'The total enterprise value of the company.',
				dataType: 'integer',
				example: 70591251682,
			},
			{
				varName: 'eps_basic',
				description: 'The basic earnings per share.',
				dataType: 'float',
				example: 2.371802305,
			},
			{
				varName: 'fifty_two_week_change',
				description: 'The percentage change in stock price over the last 52 weeks.',
				dataType: 'float',
				example: -0.4160574,
			},
			{
				varName: 'gross_profit',
				description: 'The company’s gross profit.',
				dataType: 'integer',
				example: 17289496400,
			},
			{
				varName: 'market_capitalization',
				description: 'The company’s total market capitalization.',
				dataType: 'integer',
				example: 70591251682,
			},
			{
				varName: 'revenue',
				description: 'The company’s total revenue.',
				dataType: 'integer',
				example: 57862052445,
			},
			{
				varName: 'employees',
				description: 'The total number of employees at the company.',
				dataType: 'integer',
				example: 90000,
			},
			{
				varName: 'website',
				description: 'The URL of the company’s official website.',
				dataType: 'string',
				example: 'https://www.theheinekencompany.com',
			},
			{
				varName: 'description',
				description: 'A brief description of the company’s operations.',
				dataType: 'string',
				example: 'Heineken N.V. brews and sells beer globally.',
			},
			{
				varName: 'CEO',
				description: 'The name of the company’s Chief Executive Officer.',
				dataType: 'string',
				example: 'Mr. Rudolf Gijsbert Servaas van den Brink',
			},
			{
				varName: 'address',
				description: 'The company’s headquarters address.',
				dataType: 'string',
				example: 'Tweede Weteringplantsoen 21',
			},
			{
				varName: 'city',
				description: 'The city where the company’s headquarters is located.',
				dataType: 'string',
				example: 'Amsterdam',
			},
			{
				varName: 'zip',
				description: 'The postal code of the company’s headquarters.',
				dataType: 'string',
				example: '1017 ZD',
			},
			{
				varName: 'country',
				description: 'The country where the company is headquartered.',
				dataType: 'string',
				example: 'Netherlands',
			},
			{
				varName: 'phone',
				description: 'The company’s contact phone number.',
				dataType: 'string',
				example: '+31 20 523 9590',
			},
		],
	},
	{
		id: '1',
		detailsUrl: `${PUBLIC_PATH}/dataset/1`,
		title: 'Corporate Carbon Emissions',
		icon: Co2Icon,
		keyFeatures: [
			'Extensive Coverage: Carbon emissions data spanning Scope 1, 2, and 3 for over 50,000 companies worldwide, with reliable modeled data filling gaps in reporting.',
			'Historical Data: comprehensive coverage since 2017',
			'Reliable Modeling and Verification: Leveraging human and AI verification for accurate modeling and automatic data extraction ensures dependable insights across all regions and sectors.',
		],
		paragraphs: [
			'Our Carbon Emissions Data product provides comprehensive global coverage of Scope 1, 2, and 3 emissions for over 50,000 companies, combining reported data with advanced modeling to fill critical gaps and ensure a robust, all-encompassing view of corporate emissions. This dataset extends coverage to companies with limited or no disclosed data, offering historical insights since 2017 and enabling thorough analysis for financial and sustainability assessments. Leveraging human expertise and AI verification, it ensures accurate modeling and reliable data extraction across all regions and sectors.',
		],
		extra: 'EXTRA',
		snowflakeUrl:
			'https://app.snowflake.com/marketplace/listing/GZSYZRC7DR/oxari-carbon-emissions-data-50-000-global-companies',
		useCases: [
			'ESG Investment Analysis: Investors use the dataset to analyze the sustainability and climate impact of potential investments, assessing both reported and modeled emissions across scopes. This helps in aligning portfolios with ESG targets and identifying leaders and laggards in emission reduction efforts.',
			'Economic Impact Analysis: Analysts can leverage the emissions data to study the economic implications of corporate emissions, examining how emission reduction commitments may impact financial performance, supply chains, and overall market stability.',
			'Risk Analysis: By analyzing emissions data, risk professionals can gauge a company’s exposure to climate-related regulatory risks, such as carbon taxes or penalties. This also assists in understanding transition risks associated with shifts to low-carbon technologies and practices.',
			'Market Analysis: With extensive emissions data, market analysts can forecast industry trends, evaluate sectoral performance on emissions targets, and assess competitive positioning in response to regulatory pressures and consumer demand for sustainability.',
			"Quantitative Analysis: Quantitative researchers benefit from this high-quality emissions data by incorporating it into environmental impact assessments, developing carbon metrics, or benchmarking companies' emissions performance against peers. This data product is ideal for ESG analysts, investors, and financial professionals who need reliable, extensive carbon emissions data to make data-driven decisions that align with climate goals and regulatory requirements.",
		],
		keywords: [
			'carbon emissions',
			'sustainability',
			'Scope 1, 2, and 3',
			'climate analysis',
			'global impact',
		],

		dataDictionary: [
			{
				varName: 'year',
				description: 'Represents the year of the record.',
				dataType: 'integer',
				example: 2019,
			},
			{
				varName: 'country_code',
				description: 'The country code where the company is headquartered.',
				dataType: 'string',
				example: 'CAN',
			},
			{
				varName: 'ticker',
				description: 'The stock ticker symbol for the company.',
				dataType: 'string',
				example: '0UKI.XLON',
			},
			{
				varName: 'exchange',
				description: 'The stock exchange where the company is listed.',
				dataType: 'string',
				example: 'XLON',
			},
			{
				varName: 'exchange_timezone',
				description: 'The timezone of the exchange.',
				dataType: 'string',
				example: 'Europe/London',
			},
			{
				varName: 'name',
				description: 'The full name of the company.',
				dataType: 'string',
				example: 'The Bank of Nova Scotia',
			},
			{
				varName: 'symbol',
				description: 'The symbol used to identify the company on the exchange.',
				dataType: 'string',
				example: '0UKI',
			},
			{
				varName: 'scope_1',
				description: 'The company’s direct greenhouse gas emissions (Scope 1).',
				dataType: 'float',
				example: 15729,
			},
			{
				varName: 'scope_2',
				description: 'The company’s indirect emissions from purchased energy (Scope 2).',
				dataType: 'float',
				example: 111990,
			},
			{
				varName: 'scope_3',
				description: 'Other indirect emissions in the supply chain (Scope 3).',
				dataType: 'float',
				example: 20020,
			},
			{
				varName: 'is_pred_s1',
				description: 'Indicates whether Scope 1 emissions are predicted.',
				dataType: 'integer',
				example: 0,
			},
			{
				varName: 'is_pred_s2',
				description: 'Indicates whether Scope 2 emissions are predicted.',
				dataType: 'integer',
				example: 0,
			},
			{
				varName: 'is_pred_s3',
				description: 'Indicates whether Scope 3 emissions are predicted.',
				dataType: 'integer',
				example: 0,
			},
			{
				varName: 'industry_name',
				description: 'The company’s industry classification.',
				dataType: 'string',
				example: 'Banks—Diversified',
			},
			{
				varName: 'region',
				description: 'The region where the company operates.',
				dataType: 'string',
				example: 'Americas',
			},
			{
				varName: 'sector_name',
				description: 'The economic sector of the company.',
				dataType: 'string',
				example: 'Financial Services',
			},
			{
				varName: 'sub_region',
				description: 'The sub-region classification of the company.',
				dataType: 'string',
				example: 'Northern America',
			},
			{
				varName: 'revenue',
				description: 'The total revenue of the company.',
				dataType: 'float',
				example: 10599679.67,
			},
			{
				varName: 'total_assets',
				description: 'The total assets of the company.',
				dataType: 'float',
				example: 105464825.8,
			},
			{
				varName: 'mic_code',
				description: 'The Market Identifier Code for the stock exchange.',
				dataType: 'string',
				example: 'XLON',
			},
			{
				varName: 'employees',
				description: 'The total number of employees at the company.',
				dataType: 'integer',
				example: 89249,
			},
			{
				varName: 'website',
				description: 'The official website URL of the company.',
				dataType: 'string',
				example: 'https://www.scotiabank.com',
			},
			{
				varName: 'description',
				description: 'A brief description of the company’s operations and products.',
				dataType: 'string',
				example:
					'The Bank of Nova Scotia provides various banking products and services in Canada, the United States, Mexico, Peru, Chile, Colombia, and other international regions.',
			},
			{
				varName: 'CEO',
				description: 'The Chief Executive Officer of the company.',
				dataType: 'string',
				example: 'Mr. L. Scott Thomson',
			},
			{
				varName: 'address',
				description: 'The company’s physical headquarters address.',
				dataType: 'string',
				example: '40 Temperance Street',
			},
			{
				varName: 'address2',
				description: 'Additional address information, if applicable.',
				dataType: 'string',
				example: '(empty)',
			},
			{
				varName: 'city',
				description: 'The city where the headquarters is located.',
				dataType: 'string',
				example: 'Toronto',
			},
			{
				varName: 'zip',
				description: 'The postal code for the company’s headquarters.',
				dataType: 'string',
				example: 'M5H 0B4',
			},
			{
				varName: 'state',
				description: 'The state or region of the company’s headquarters.',
				dataType: 'string',
				example: 'ON',
			},
			{
				varName: 'country',
				description: 'The country of the company’s headquarters.',
				dataType: 'string',
				example: 'Canada',
			},
			{
				varName: 'phone',
				description: 'The phone number for the company’s headquarters.',
				dataType: 'string',
				example: '+1 416 866 6161',
			},
			{
				varName: 'revenue_intensity_scope_1',
				description: 'The intensity of Scope 1 emissions relative to revenue.',
				dataType: 'float',
				example: 0.001483913,
			},
			{
				varName: 'total_assets_intensity_scope_1',
				description: 'The intensity of Scope 1 emissions relative to total assets.',
				dataType: 'float',
				example: 0.00014914,
			},
			{
				varName: 'employees_intensity_scope_1',
				description: 'The intensity of Scope 1 emissions relative to the number of employees.',
				dataType: 'float',
				example: 0.176237269,
			},
			{
				varName: 'revenue_intensity_scope_2',
				description: 'The intensity of Scope 2 emissions relative to revenue.',
				dataType: 'float',
				example: 0.010565414,
			},
			{
				varName: 'total_assets_intensity_scope_2',
				description: 'The intensity of Scope 2 emissions relative to total assets.',
				dataType: 'float',
				example: 0.001061871,
			},
			{
				varName: 'employees_intensity_scope_2',
				description: 'The intensity of Scope 2 emissions relative to the number of employees.',
				dataType: 'float',
				example: 1.254803975,
			},
			{
				varName: 'revenue_intensity_scope_3',
				description: 'The intensity of Scope 3 emissions relative to revenue.',
				dataType: 'float',
				example: 0.001888736,
			},
			{
				varName: 'total_assets_intensity_scope_3',
				description: 'The intensity of Scope 3 emissions relative to total assets.',
				dataType: 'float',
				example: 0.000189826,
			},
			{
				varName: 'employees_intensity_scope_3',
				description: 'The intensity of Scope 3 emissions relative to the number of employees.',
				dataType: 'float',
				example: 0.224316239,
			},
		],
	},
	{
		id: '2',
		title: 'Temperature Alignment Score',
		detailsUrl: `${PUBLIC_PATH}/dataset/2`,
		icon: ThermostatIcon,
		keyFeatures: [
			'Paris Agreement Alignment: Determines a company’s temperature trajectory, indicating whether it aligns with 1.5°C or 2°C climate targets',
			'Comprehensive Data Integration: Uses historical and forecasted emissions data from our Carbon Emissions Data product for over 50,000 companies, including Scope 1, 2, and 3 emissions.',
			'Scalable Forecasting Model: The Temperature Alignment Score forecasts up to 2100, enabling long-term impact assessments and strategic planning.',
		],
		paragraphs: [
			"Our Temperature Alignment Score is a forward-looking metric that evaluates a company's alignment with the Paris Agreement goals, providing insight into whether its emissions trajectory is consistent with keeping global warming within 1.5°C or 2°C. By assessing current emissions data and forecasting future emissions reductions, the Temperature Alignment Score delivers a clear and actionable benchmark of climate responsibility, making it an essential tool for investors, analysts, and ESG professionals. We offer an entire dataset for over 50.000 companies.",
			'The Temperature Alignment Score is an indispensable tool for anyone working toward a low-carbon future, delivering crucial insights into corporate climate alignment and helping drive effective, impactful climate action across industries.',
		],
		extra: '',
		snowflakeUrl:
			'https://app.snowflake.com/marketplace/listing/GZSYZRC7CU/oxari-temperature-alignment-score-tas-assessing-2%C2%B0c-alignment-for-50-000-global-companies',
		useCases: [
			'ESG analysts use the Temperature Alignment Score to evaluate how closely companies align with climate targets, supporting sustainable investment decisions. The metric helps identify climate leaders and laggards within a portfolio, aiding in the construction of environmentally conscious investment strategies.',
			'Corporations can benchmark their climate performance against peers to understand how well they align with Paris Agreement objectives, which is essential for climate reporting, strategic planning, and setting internal sustainability targets.',
			'The Temperature Alignment Score allows risk managers to identify companies with potential exposure to regulatory or reputational risks stemming from inadequate climate commitments. Understanding alignment can inform decisions on which companies may face increased pressure to adjust their practices.',
			'Policymakers and analysts can use the score to assess industry and sectoral progress toward climate goals, identify areas that require stricter regulations, and design policies that encourage alignment with the Paris targets.',
			'Quantitative researchers and climate modelers can incorporate the Temperature Alignment Score into broader climate risk assessments and financial models. The score provides a standardized metric for analyzing climate impact and integrating emissions trajectories into economic forecasting.',
		],
		keywords: [
			'temperature alignment',
			'Paris Agreement',
			'climate targets',
			'emissions forecasting',
		],

		dataDictionary: [
			{
				varName: 'Company',
				description: 'The name of the company.',
				dataType: 'string',
				example: 'The Coca-Cola Company',
			},
			{
				varName: 'Symbol',
				description: 'The stock ticker symbol of the company.',
				dataType: 'string',
				example: 'KO',
			},
			{
				varName: 'Sector',
				description: 'The business sector the company operates in.',
				dataType: 'string',
				example: 'Consumer Defensive',
			},
			{
				varName: 'Industry',
				description: 'The specific industry within the sector.',
				dataType: 'string',
				example: 'Beverages—Non-Alcoholic',
			},
			{
				varName: 'Description of activities',
				description: "A brief description of the company's operations and key activities.",
				dataType: 'string',
				example: 'Manufactures and markets beverages',
			},
			{
				varName: 'Country',
				description: 'The country where the company is headquartered.',
				dataType: 'string',
				example: 'United States',
			},
			{
				varName: 'Market Identifier Code',
				description:
					'The market identifier code representing the stock exchange where the company is listed.',
				dataType: 'string',
				example: 'XNYS',
			},
			{
				varName: 'TA Policy-Lenient, Technology-Poor',
				description:
					'The score for the company under lenient tax avoidance policy and poor technology conditions.',
				dataType: 'float',
				example: 2.92,
			},
			{
				varName: 'TA Policy-Lenient, Technology-Moderate',
				description:
					'The score for the company under lenient tax avoidance policy and moderate technology conditions.',
				dataType: 'float',
				example: 2.85,
			},
			{
				varName: 'TA Policy-Lenient, Technology-Strong',
				description:
					'The score for the company under lenient tax avoidance policy and strong technology conditions.',
				dataType: 'float',
				example: 2.4,
			},
			{
				varName: 'TA Policy-Moderate, Technology-Poor',
				description:
					'The score for the company under moderate tax avoidance policy and poor technology conditions.',
				dataType: 'float',
				example: 2.51,
			},
			{
				varName: 'TA Policy-Moderate, Technology-Moderate',
				description:
					'The score for the company under moderate tax avoidance policy and moderate technology conditions.',
				dataType: 'float',
				example: 2.28,
			},
			{
				varName: 'TA Policy-Moderate, Technology-Strong',
				description:
					'The score for the company under moderate tax avoidance policy and strong technology conditions.',
				dataType: 'float',
				example: 2.03,
			},
			{
				varName: 'TA Policy-Strict, Technology-Poor',
				description:
					'The score for the company under strict tax avoidance policy and poor technology conditions.',
				dataType: 'float',
				example: 2.43,
			},
			{
				varName: 'TA Policy-Strict, Technology-Moderate',
				description:
					'The score for the company under strict tax avoidance policy and moderate technology conditions.',
				dataType: 'float',
				example: 2.37,
			},
			{
				varName: 'TA Policy-Strict, Technology-Strong',
				description:
					'The score for the company under strict tax avoidance policy and strong technology conditions.',
				dataType: 'float',
				example: 1.86,
			},
		],
	},
	{
		id: '3',
		title: 'Carbon Emissions Forecast',
		detailsUrl: `${PUBLIC_PATH}/dataset/3`,
		icon: QueryStatsIcon,
		keyFeatures: [
			'Long-Term Emissions Forecasting: Projects emissions trajectories up to 2100, offering insight into long-term climate alignment and potential regulatory risks.',
			'Scenario Analysis: Includes nine IPCC-aligned emissions scenarios, from immediate climate action to moderate or limited intervention, for comparative decision-making.',
			'Data Integration Across All Scopes: Utilizes comprehensive Scope 1, 2, and 3 emissions data, enabling scenario-relevant, reliable projections tailored to industry-specific and global trends.',
		],
		paragraphs: [
			'Oxari’s Carbon Emissions Forecaster is a robust tool designed to project a company’s emissions trajectory from 2025 to 2100. By combining historical emissions data, emissions targets, and historical percentage changes with IPCC’s global scenarios, the Forecaster provides a comprehensive view of potential future impacts. This forecast spans three critical periods: the short run, transition period, and long run, each progressively integrating IPCC scenarios to account for policy and technological developments, including carbon sequestration.',
			'The Carbon Emissions Forecaster provides essential insights for any organization committed to understanding and mitigating long-term carbon impacts, equipping decision-makers with the data they need to lead in a carbon-constrained future.',
		],
		extra: '',
		snowflakeUrl:
			'https://app.snowflake.com/marketplace/listing/GZSYZRC7E0/oxari-carbon-emissions-forecast-years-2025-to-2100-for-50-000-companies',
		useCases: [
			'Investors can integrate emissions forecasts into ESG investment analyses to evaluate the long-term climate impact of their portfolios. The Forecaster helps identify companies with positive or negative emissions trends, aiding in portfolio alignment with climate goals and supporting sustainable investment strategies.',
			'Risk managers leverage the Carbon Emissions Forecaster to understand potential exposure to future carbon regulations or market shifts. By projecting emissions trends, organizations can assess and mitigate transition risks associated with failing to align with climate commitments.',
			'Corporations use the Forecaster to benchmark emissions reduction strategies, assess future regulatory impacts, and align internal climate action plans with long-term goals. This helps companies refine their strategies to reduce emissions over time effectively.',
			'Policymakers and regulatory analysts can use the Forecaster to study projected emissions trends across industries and sectors. The projections provide critical insight into potential regulatory needs and support policy design that encourages sector-wide emissions reductions.',
			'Quantitative researchers and financial analysts can incorporate emissions forecasts into economic models, supporting complex financial analyses and modeling the impact of different emissions pathways on broader market performance.',
		],
		keywords: [
			'carbon forecasting',
			'emissions scenarios',
			'long-term projections',
			'climate modeling',
		],
		dataDictionary: [
			{
				varName: 'key_ticker',
				description: 'A ticker symbol representing the company.',
				dataType: 'string',
				example: 'AAPL.XNGS',
			},
			{
				varName: 'meta_name',
				description: 'The company name associated with the ticker.',
				dataType: 'string',
				example: 'Apple Inc.',
			},
			{
				varName: 'bucket',
				description:
					"A tuple indicating a policy and risk level scenario, such as 'low' or 'high'.",
				dataType: 'string (tuple)',
				example: "('low', 'low')",
			},
			{
				varName: 'policy_factor',
				description: 'Represents the policy risk scenario.',
				dataType: 'string',
				example: 'low',
			},
			{
				varName: 'technological_development',
				description: 'Describes the level of technological development in the scenario.',
				dataType: 'string',
				example: 'low',
			},
			{
				varName: 'variable',
				description:
					'The type of value being measured, such as the average index, lower bound, or upper bound.',
				dataType: 'string',
				example: 'average_index',
			},
			{
				varName: 'year columns (2020-2100)',
				description:
					'Forecasted values for carbon emissions or other metrics for each respective year.',
				dataType: 'numeric (float or integer)',
				example: '2.26E+09',
			},
		],
	},
	{
		id: '4',
		detailsUrl: `${PUBLIC_PATH}/dataset/4`,
		title: 'Corporate SFDR Metrics',
		icon: AssuredWorkloadIcon,
		keyFeatures: [
			'Environmental Metrics: Comprehensive data on Scope 1, 2, and (where available) Scope 3 emissions, energy consumption, waste generation, water usage, and biodiversity impacts.',
			'Social Factors: Employee diversity, gender pay gap, global labor standard violations, and community engagement metrics.',
			'Governance Insights: Board diversity, corporate ethics, and anti-corruption practices.',
			'Fiscal Year Alignment: Precise fiscal year-end data and deviations for compatibility with SFDR reporting standards.',
			'Source Referencing: Traceable data linked to corporate sustainability reports, regulatory filings, and other verified sources.',
			'ISIN Mapping: Integration with financial instruments for streamlined ESG analysis.',
		],
		paragraphs: [
			'Our SFDR Corporate Sustainability Data is a comprehensive dataset tailored to meet the rigorous requirements of the Sustainable Finance Disclosure Regulation (SFDR). Covering over 50,000 companies globally, it offers a detailed and multi-faceted view of corporate sustainability performance, providing investors, ESG analysts, and financial professionals with the data needed to drive informed decision-making.',
			'The dataset includes extensive environmental, social, and governance metrics aligned with SFDR principals. It spans greenhouse gas emissions (Scope 1, Scope 2, and where available, Scope 3), energy consumption and intensity, waste generation, water usage, and biodiversity impacts. Additionally, the dataset captures social factors such as employee diversity, gender pay gaps, and violations of global labor standards, as well as governance metrics like board diversity and corporate ethics practices.',
			'The fiscal year-end alignment and ISIN mapping ensure seamless integration with financial instruments, facilitating SFDR-aligned reporting, portfolio construction, and compliance analysis. With sector-wide and regional coverage, the dataset is particularly valuable for benchmarking company performance, supporting regulatory reporting, and aligning portfolios with climate goals.',
			'Whether you are optimizing an ESG portfolio, conducting regulatory compliance checks, or assessing climate-related risks, this dataset is your essential tool for accurate, actionable insights into corporate sustainability.',
		],
		extra: 'EXTRA',
		snowflakeUrl:
			'https://app.snowflake.com/marketplace/listing/GZSYZRC7E4/oxari-financial-data-for-50-000-companies-since-2017',
		useCases: [
			'ESG Portfolio Optimization: Investors can leverage this dataset to design and optimize ESG-focused portfolios, aligning investments with sustainability goals and enhancing performance.',
			'SFDR-Aligned Reporting: Financial professionals use this data to ensure compliance with SFDR regulations, providing structured and reliable insights for accurate reporting.',
			'Corporate Sustainability Benchmarking: Analysts benchmark companies on ESG performance and climate alignment, enabling detailed comparisons across sectors and regions.',
			'Assessing Climate Alignment: Organizations assess alignment with global climate objectives, identifying opportunities to enhance sustainability practices.',
			'Regulatory Compliance Checks: Compliance teams utilize this dataset to perform regulatory checks, ensuring adherence to sustainability-related financial requirements.',
			'Climate-Related Risk Assessment: Risk managers analyze climate-related risks using comprehensive metrics to identify potential financial vulnerabilities.',
			'ESG Performance Benchmarking: Companies benchmark their ESG performance against industry standards to improve sustainability initiatives and competitiveness.',
			'Portfolio Climate Goal Alignment: Asset managers align investment portfolios with climate goals, leveraging detailed environmental metrics for informed decision-making.',
			'Sustainability-Driven Investment Analysis: Financial analysts integrate actionable sustainability data to drive strategic investment decisions.',
			'Trusted Regulatory Reporting Support: Organizations use the dataset to facilitate accurate and reliable reporting, meeting both regulatory and internal standards.',
		],
		keywords: [
			'ESG',
			'SFDR',
			'portfolio optimization',
			'sustainability data',
			'climate alignment',
			'regulatory compliance',
			'risk assessment',
			'corporate benchmarking',
			'investment analysis',
			'climate-related risks',
			'sustainability metrics',
			'environmental metrics',
			'social factors',
			'governance insights',
			'greenhouse gas emissions',
			'energy consumption',
			'waste generation',
			'water usage',
			'biodiversity impacts',
			'employee diversity',
			'gender pay gap',
			'board diversity',
			'corporate ethics',
			'anti-corruption',
			'ISIN mapping',
			'regulatory reporting',
			'financial instruments',
			'climate goals',
			'compliance analysis',
			'benchmarking',
			'trusted data',
			'actionable insights',
		],
		dataDictionary: [],
	},
];
