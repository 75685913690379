import React, { useState } from 'react';
import {
	Box,
	Card,
	CardContent,
	Button,
	CardActions,
	Typography,
	Snackbar,
	Alert,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { LUCRUM_URL } from './constants';

const currencyUnit = '€';

function InfoRow(props) {
	const theme = useTheme();
	const { availability } = props;
	const icon = availability ? (
		<CheckCircleRoundedIcon sx={{ color: theme.palette.primary.light }} />
	) : (
		<HighlightOffRoundedIcon />
	);

	return (
		<Typography variant='body2' color='textPrimary'>
			{icon} {props.children}
		</Typography>
	);
}

const PriceHeader = ({ title, price }) => {
	return (
		<Box align='center' my={3} textTransform={'capitalize'}>
			<Typography variant='h2' color='primary.bright_green' mb={1}>
				{title}
			</Typography>
			<Typography variant='h4' color='secondary.dark'>
				{typeof price === 'number' ? `${currencyUnit} ${price}` : price}
			</Typography>
			{typeof price === 'number' && (
				<Typography variant='subtitle2' color='secondary.dark' align='center'>
					per year
				</Typography>
			)}
		</Box>
	);
};

export const CustomPricingCard = React.memo(function CPricingCard({ subscription }) {
	const emailText = 'contact@oxari.io';
	const [open, setOpen] = useState(false);
	const theme = useTheme();

	function handleClick(e) {
		e.preventDefault();
		navigator.clipboard.writeText(emailText);
		setOpen(true);
	}

	const handleClose = (reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	return (
		<Card
			sx={{
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				maxWidth: '280px',
				margin: 'auto',
			}}
		>
			<PriceHeader title={subscription.name} price={'contact sales'} />
			<CardContent>
				<Box display='flex' justifyContent={'center'} flexDirection='column' alignItems={'center'}>
					<Typography variant='subtitle2'>
						Wondering which subscription plan would suit you? We will be happy to accomodate your
						request by offering customised subscriptions according to your needs.
					</Typography>
				</Box>
			</CardContent>
			<CardActions>
				<Box display='flex' flexDirection='column' flexGrow={1}>
					<Typography variant='subtitle2' mb={1} textAlign='center'>
						Please send us an email at:
					</Typography>
					<Button
						variant='containedOutline'
						color='whiteToGreen'
						startIcon={<EmailOutlinedIcon />}
						onClick={handleClick}
						sx={{
							backgroundColor: theme.palette.secondary.light,
							textTransform: 'lowercase',
						}}
					>
						{emailText}
					</Button>
				</Box>

				<Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
					<Alert elevation={6} variant='filled' severity='success'>
						Copied email address
					</Alert>
				</Snackbar>
			</CardActions>
		</Card>
	);
});

export const PricingCard = React.memo(function PCard({ subscription }) {
	const theme = useTheme();

	return (
		<Card sx={{ maxWidth: '280px', margin: 'auto' }}>
			<PriceHeader title={subscription.name} price={subscription.price} />
			<CardContent>
				<Box mx='auto' width='fit-content'>
					<InfoRow availability={true}>
						{`${currencyUnit}${((subscription.price * 100) / subscription.annual_credits).toFixed(
							1
						)} per 100 credits`}
					</InfoRow>
					<InfoRow availability={true}>
						{`${subscription.annual_credits.toLocaleString()} annual credits`}
					</InfoRow>

					<InfoRow availability={true}>Historical data</InfoRow>
					<InfoRow availability={true}>Latest financial data</InfoRow>
					{/* <InfoRow availability={subscription.reports === '0' ? false : true}>
						{subscription.reports} Reports
					</InfoRow> */}
					<InfoRow availability={subscription.commercial_usage}>Commercial usage</InfoRow>
				</Box>
			</CardContent>

			<CardActions>
				<Button
					// id={subscription.type}
					// variant='contained'
					disableElevation={true}
					fullWidth={true}
					sx={{
						marginTop: 4,
						bgcolor: theme.palette.primary.light,
						border: 1,
						borderColor: 'transparent',
						color: theme.palette.secondary.dark,
						textTransform: 'capitalize',
						'&:hover': {
							backgroundColor: '#A1F8B4',
							color: '#000000',
						},
					}}
					type={'submit'}
					justifycontent='center'
					onClick={() => {
						window.open(
							`${LUCRUM_URL}/checkout?plan=${subscription.name.toLowerCase().replace(' ', '_')}`,
							'_blank'
						);
					}}
				>
					Select
				</Button>
			</CardActions>
		</Card>
	);
});
export default PricingCard;
