import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Badge, Box, Container, Grid, Typography, Button } from '@mui/material';
import { datasets } from '../misc/text/datasets';
import DatasetCard from '../components/dataset_card';
import { Link } from 'react-router-dom';

export function DatasetsOverviewPage() {
	const [state, setState] = React.useState(0);

	const handleProductCardOnClick = (productId) => {
		setState(productId);
	};

	return (
		<Box>
			<Helmet key='dataset'>
				<meta charSet='utf-8' />
				<title>Oxari Datasets | Sustainability Intelligence Solutions</title>
				<meta
					name='description'
					content="Discover Oxari's advanced datasets for Sustainability Intelligence, including Temperature Alignment Scoring, Corporate Carbon Estimation, ESG Dashboards, and more. Empower your sustainability strategies with precise, actionable insights."
				/>
				<meta
					name='keywords'
					content='Sustainability intelligence, carbon intelligence, ESG datasets, corporate carbon estimation, temperature alignment scoring, sustainable investments, Oxari datasets, climate analytics'
				/>
				<meta name='author' content='Oxari Team' />
				<link rel='canonical' href='https://oxari.io/en/datasets' />

				{/* Open Graph Meta Tags */}
				<meta
					property='og:title'
					content='Oxari Datasets | Sustainability Intelligence Solutions'
				/>
				<meta
					property='og:description'
					content='Explore Oxari’s datasets for corporate sustainability, including carbon intelligence and temperature alignment scoring. Perfect for investors, policymakers, and ESG professionals.'
				/>
				<meta property='og:type' content='website' />
				<meta property='og:url' content='https://oxari.io/en/datasets' />

				{/* Twitter Meta Tags */}
				<meta name='twitter:card' content='summary_large_image' />
				<meta
					name='twitter:title'
					content='Oxari Datasets | Sustainability Intelligence Solutions'
				/>
				<meta
					name='twitter:description'
					content="Empower your ESG journey with Oxari's advanced datasets. Analyze corporate carbon emissions, assess temperature alignment, and drive sustainable decision-making."
				/>

				{/* JSON-LD Structured Data */}
				<script type='application/ld+json'>
					{JSON.stringify({
						'@context': 'https://schema.org',
						'@type': 'WebPage',
						name: 'Datasets | Oxari',
						url: 'https://oxari.io/en/datasets',
						description:
							"Discover Oxari's datasets for corporate sustainability insights, featuring Temperature Scoring, Corporate Carbon Estimation, ESG Dashboards, and more.",
						publisher: {
							'@type': 'Organization',
							name: 'Oxari B.V.',
							logo: {
								'@type': 'ImageObject',
								url: 'https://oxari.io/en/oxari.png',
								width: 250,
								height: 250,
							},
						},
						mainEntity: datasets.map((ds) => ({
							'@type': 'Dataset',
							name: ds.title,
							description: ds.paragraphs[0],
							url: ds.detailsUrl,
							keywords: ds.keywords,
							creator: {
								'@type': 'Organization',
								name: 'Oxari',
							},
						})),
					})}
				</script>
			</Helmet>

			<Container>
				<Box
					paddingTop={6}
					paddingBottom={3}
					sx={{ textAlign: { xs: 'center', sm: 'center', md: 'left' } }}
				>
					<Typography variant='pageHeader'>Sustainability Intelligence Datasets</Typography>
				</Box>

				<Box
					my={6}
					sx={{
						width: { md: '70%' },
					}}
				>
					<Typography
						variant='h4'
						sx={{
							textAlign: {
								xs: 'center',
								sm: 'left',
								md: 'left',
							},
						}}
					>
						All the information you need to support evaluating sustainability performance, assessing
						climate-related risks, identifying low-carbon opportunities, constructing risk or factor
						oriented models, and making informed ESG-aligned investment decisions.
					</Typography>
				</Box>

				<Grid container mb={6}>
					{datasets.map((product, i) => (
						<Grid item xs={6} sm={6} md={3} p={1} key={`productCard${i}`}>
							<Badge
								color='primary'
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								sx={{
									width: '100%',
									height: '100%',
									'& > span': {
										transform: 'scale(1) translate(15%, -50%)',
									},
								}}
							>
								<Box
									onClick={() => handleProductCardOnClick(product.id)}
									sx={{
										display: 'flex',
										alignItems: 'center',
										flexDirection: 'column',
										flex: '1 1 50%',
										borderRadius: '1.25rem',
										backgroundColor: parseInt(state) === i ? '#A1F8B4' : '#D9D9D9',
										minHeight: '180px',
										p: 2,
										color: 'black',
										textTransform: 'none',
										'&:hover': { backgroundColor: '#A1F8B4' },
									}}
								>
									<product.icon sx={{ fontSize: '3rem', margin: 1 }} />
									<Typography
										variant='h6'
										textAlign={'center'}
										maxWidth='300px'
										fontWeight={'600'}
										m='auto'
									>
										{product.title}
									</Typography>
								</Box>
							</Badge>
						</Grid>
					))}
				</Grid>
			</Container>

			<DatasetCard
				id={datasets[state].id}
				title={datasets[state].title}
				list={datasets[state].keyFeatures}
				paragraphs={datasets[state].paragraphs}
				extra={datasets[state].extra}
				snowflakeUrl={datasets[state].snowflakeUrl}
			/>
		</Box>
	);
}

export function TrySampleButton({ snowflakeUrl }) {
	return (
		<Button variant='green' href={snowflakeUrl} target='_blank'>
			Try Sample
		</Button>
	);
}

export function MoreInfoButton({ id }) {
	return (
		<Button variant='green' component={Link} to={'/dataset/' + id}>
			More Info...
		</Button>
	);
}
