import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { Helmet } from 'react-helmet-async';
import PostTemplete from '../components/post_templete';
import { showAuthors } from '../misc/utils';
import axios from 'axios';

const BLOG_URL = process.env.REACT_APP_STRAPI_BLOG_URL || 'http://localhost:1337/api';

export function PostPage() {
	const { slug } = useParams();
	const history = useHistory();
	const postId = slug.split('-')[0];
	const [post, setPost] = useState(null);

	useEffect(() => {
		const getPost = async (id) => {
			try {
				const response = await axios.get(`${BLOG_URL}/posts/${id}`);
				setPost(response.data.data);
			} catch (err) {
				history.push('/404');
			}
		};
		getPost(postId);
	}, [postId, history]); // Added history as a dependency to suppress linting issues

	return (
		<div className='my-5'>
			{post && (
				<>
					<Helmet>
						<title>{post.attributes.title} | My Blog</title>
						<meta
							name='description'
							content={post.attributes.description || 'Read our latest blog post.'}
						/>
						<meta property='og:title' content={post.attributes.title} />
						<meta property='og:description' content={post.attributes.description} />
						<meta
							property='og:image'
							content={post.attributes.picture?.data.attributes.formats.large?.url}
						/>
						<meta property='og:url' content={`${BLOG_URL}/posts/${slug}`} />
						<meta name='twitter:card' content='summary_large_image' />
						<meta name='twitter:title' content={post.attributes.title} />
						<meta name='twitter:description' content={post.attributes.description} />
						<meta
							name='twitter:image'
							content={post.attributes.picture?.data.attributes.formats.large?.url}
						/>
						<link rel='canonical' href={`${BLOG_URL}/posts/${slug}`} />
					</Helmet>
					<PostTemplete
						title={post.attributes.title}
						sub={post.attributes.sub}
						content={post.attributes.content}
						picture={
							post.attributes.picture?.data.attributes.formats.large?.url ||
							post.attributes.picture?.data.attributes.formats.medium.url
						}
						author={showAuthors(post.attributes.authors, post.attributes.createdBy)}
						publishDate={post.attributes.publishedAt?.split('T')[0]}
						reactions={[post.attributes.hearts, post.attributes.trees, post.attributes.applause]}
						path={`${BLOG_URL}/posts/${post.id}`}
					/>
				</>
			)}
		</div>
	);
}

export default PostPage;
